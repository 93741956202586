import React from 'react'

import { Dialog } from 'primereact/dialog'
import SelectMenu from '../selectMenu'
import DialogFooter from '../dialogFooter'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import FormGroup from '../form-group'
import DiaDaSemanaSelect from './diaDaSemanaSelectComponent'
import DiasDoMesMultiSelect from './diasDoMesMultiSelectComponent'

import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import InputChooseContaBancaria from '../conciliacaoBancaria/inputChooseContaBancaria'
import ContaContabilViewInsideDialog from '../planoDeContas/contaContabilViewInsideDialog'
import CheckBoxComponent from '../relatorios/checkBoxComponent'
import ConstantsUtil from '../../context/constantsUtil'

class EditTipoDeRecebimentoDialog extends React.Component {
    
    constructor(){
        super();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
    }

    state = {

        id: null,

        numeroDeChamada: null,
        inputNumeroDeChamadaErrorClass: '',
        errornNumeroDeChamadaMessage: 'N° de chamada é obrigatório',

        nomeTipoDeRecebimento: '',
        inputNomeErrorClass: '',
        errorNomeMessage: '',

        prazo: '',
        inputPrazoErrorClass: '',
        errorPrazoMessage: '',
        
        taxa: '',
        inputTaxaErrorClass: '',
        errorTaxaMessage: '',

        tipoDeCorte: '',
        inputTipoCorteErrorClass: '',
        errorTipoCorteMessage: '',
        
        diaDaSemana: '',
        inputDiaDaSemanaErrorClass: '',
        errorDiaDaSemanaMessage: '',

        diasDoMes: [],
        inputDiasDoMesClass: '',
        errorDiasDoMesMessage: '',
        
        didUpdated: false,
        visibleConfirmDialog: false,

        disableTaxa: false,
        disablePrazo: false,
        disableTipoDeCorte: false,

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        contaBancariaId: null,
        inputContaBancariaErrorClass: '',
        errorContaBancariaMessage: 'Selecione uma conta bancária.',

        selectedContaContabil: '',

        destacadoDRE: '',

    }


    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            // console.log("state: ", this.props.state)
            this.setState({id: this.props.state.id})
            this.setState({numeroDeChamada: this.props.state.numeroDeChamada})
            this.setState({nomeTipoDeRecebimento: this.props.state.nomeTipoDeRecebimento})
            this.setState({natureza: this.props.state.natureza})
            this.setState({prazo: this.props.state.prazo})
            this.setState({taxa: this.props.state.taxa})
            this.setState({tipoDeCorte: this.props.state.tipoDeCorte})
            this.setState({diaDaSemana: this.props.state.diaDaSemana})
            this.setState({diasDoMes: this.props.state.diasDoMes})
            this.setState({contaBancariaId: this.props.state.contaBancariaId})
            this.setState({selectedContaContabil: this.props.state.contaContabil})
            this.setState({destacadoDRE: this.props.state.destacadoDRE})
            this.resetView()

            this.setState({disableTaxa: this.disableTaxa()})
            this.setState({disablePrazo: this.disablePrazo()})
            this.setState({disableTipoDeCorte: this.disableTipoDeCorte()})
            
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }
        }
    }

    disableTaxa = () =>{
        return [
            this.tiposDeRecebimentoService.pixLabel,
            this.tiposDeRecebimentoService.dinheiroLabel,
            this.tiposDeRecebimentoService.outrosLabel,
            this.tiposDeRecebimentoService.estornoTarifaLabel
            ].includes(this.props.state.natureza)
    }

    disablePrazo = () =>{
        return [
            this.tiposDeRecebimentoService.pixLabel,
            this.tiposDeRecebimentoService.dinheiroLabel,
            this.tiposDeRecebimentoService.outrosLabel,
            this.tiposDeRecebimentoService.estornoTarifaLabel
            ].includes(this.props.state.natureza)
    }

    disableTipoDeCorte = () =>{
        return [
            this.tiposDeRecebimentoService.debitoLabel,
            this.tiposDeRecebimentoService.pixLabel,
            this.tiposDeRecebimentoService.dinheiroLabel,
            this.tiposDeRecebimentoService.outrosLabel,
            this.tiposDeRecebimentoService.estornoTarifaLabel
            ].includes(this.props.state.natureza)
    }

    resetView = () => {
        this.setState({inputNumeroDeChamadaErrorClass: ''})
        this.setState({inputNomeErrorClass: ''})
        this.setState({errorNomeMessage: ''})
        this.setState({inputPrazoErrorClass: ''})
        this.setState({errorPrazoMessage: ''})
        this.setState({inputTaxaErrorClass: ''})
        this.setState({errorTaxaMessage: ''})
        this.setState({inputTipoCorteErrorClass: ''})
        this.setState({errorTipoCorteMessage: ''})
        this.setState({inputDiaDaSemanaErrorClass: ''})
        this.setState({errorDiaDaSemanaMessage: ''})
        this.setState({inputDiasDoMesClass: ''})
        this.setState({errorDiasDoMesMessage: ''})

        this.setState({inputContaBancariaErrorClass: ''})
    }

    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleTipoDeCorteChange = async (event) => {
        this.handleChange(event)
        this.setState({diaDaSemana: null, diasDoMes: null})
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    hideContaBancariaDialog = () => {
        this.setState({chooseContaBancariaDialogVisible: false})
    }
    
    handleSelectionContaBancariaChange = (selectedContaBancaria, selectedContaBancariaLabel) => {
        this.setState({selectedContaBancaria})
        this.setState({selectedContaBancariaLabel})
    }

    checkData = () => {
        var check = true

        if(!this.state.numeroDeChamada){
            this.setState({inputNumeroDeChamadaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.nomeTipoDeRecebimento){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorNomeMessage:"Nome é obrigatório"})
            check = false
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.boletoLabel){
            return check
        }

        if(this.state.prazo === "" || this.state.prazo === null){
            this.setState({inputPrazoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorPrazoMessage:"Prazo é obrigatório"})
            check = false
        }
        if(this.state.taxa === "" || this.state.taxa === null){
            this.setState({inputTaxaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorTaxaMessage:"Taxa é obrigatória"})
            check = false
        }
        if(!this.state.tipoDeCorte){
            this.setState({inputTipoCorteErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorTipoCorteMessage:"Tipo de Corte é obrigatório"})
            check = false
        }
        if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue && !this.state.diaDaSemana){
            this.setState({inputDiaDaSemanaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorDiaDaSemanaMessage:"Dia da Semana é obrigatório"})
            check = false
        }

        if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue && (!this.state.diasDoMes || this.state.diasDoMes.length === 0 ) ){
            this.setState({inputDiasDoMesClass: "p-invalid p-d-block"})
            this.setState({errorDiasDoMesMessage:"Dia(s) do mês é(são) obrigatório(s)"})
            check = false
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.debitoLabel){
            if( parseInt(this.state.prazo) !== 0 && parseInt(this.state.prazo) !== 1 ){
                check = false
                this.setState({inputPrazoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                this.setState({errorPrazoMessage:"Prazo de Débito deve ser 0 ou 1"})
            }
        }

        if( !this.state.selectedContaBancaria && 
            [this.tiposDeRecebimentoService.pixLabel, this.tiposDeRecebimentoService.debitoLabel, this.tiposDeRecebimentoService.creditoLabel]
            .includes(this.state.natureza)
        ){
            this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }
    
    save = () => {
        this.tiposDeRecebimentoService.update(
        {
            id: this.state.id,
            numeroDeChamada: this.state.numeroDeChamada,
            nomeTipoDeRecebimento: this.state.nomeTipoDeRecebimento.trim().toUpperCase(),
            natureza: this.state.natureza,
            taxa: this.state.taxa,
            prazo: this.state.prazo,
            tipoDeCorte: this.state.tipoDeCorte,
            diaDaSemana: this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue ? this.state.diaDaSemana.toUpperCase() : "",
            diasDoMes: this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue ? this.state.diasDoMes: [],
            contaBancariaId: this.state.selectedContaBancaria.id,
            destacadoDRE: this.state.destacadoDRE
        }
        )
        .then(response => {
            popUp.successPopUp("Tipo de recebimento alterado com sucesso")
            this.props.search()
            this.hideDialog()
            this.setState({didUpdated: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
        this.setState({didUpdated: false})
        this.setState({visibleConfirmDialog: false})
    }
    
    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        const renderDialogOptionsIfNotBoleto = () => {
            if(this.state.natureza !== 'BOLETO'){
                return (
                    <>
                    <br/>
                <div className="p-field">
                    <label htmlFor="taxa">Taxa(%)</label>
                    <input style={{color: '#000'}} type="number"
                            className={"form-control " + this.state.inputTaxaErrorClass}
                            value = {this.state.taxa}
                            name="taxa"
                            onChange={this.handleChange}
                            id="inputtaxa"
                            placeholder="Digite a Taxa"
                            disabled={this.props.disabled || this.state.disableTaxa}
                            />
                    <div className="invalid-feedback">{this.state.errorTaxaMessage}</div>
                </div>

                <br/>
                <div className="p-field">
                    <label htmlFor="prazoEdit">Prazo (dias) </label>
                    <input style={{color: '#000'}}   className={"form-control " + this.state.inputPrazoErrorClass}
                                id="prazo"
                                name="prazo"
                                value={this.state.prazo}
                                // style={{marginTop: '0px', marginBottom: '0px', height: '80px'}}
                                placeholder="Digite o prazo"
                                onChange = {this.handleChange}
                                disabled={this.props.disabled || this.state.disablePrazo}
                                />
                    <div className="invalid-feedback">{this.state.errorPrazoMessage}</div>
                </div>

                <br />

                <div className="p-field">
                    <FormGroup label = "Tipo de Corte " htmlFor = "InputTipoCorte">
                        <SelectMenu
                            className={"form-control " + this.state.inputTipoCorteErrorClass}
                            name="tipoDeCorte"
                            list={this.tiposDeRecebimentoService.tipoCorteList} 
                            value={this.state.tipoDeCorte}
                            onChange={this.handleTipoDeCorteChange}
                            disabled={this.props.disabled || this.state.disableTipoDeCorte}
                        /> 
                        <div className="invalid-feedback">{this.state.errorTipoCorteMessage}</div>  
                    </FormGroup> 
                </div>
                    </>
                )
            }
        }

        const renderDiaDaSemana = () => {
            if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue)
            return (
                    <div>
                        <DiaDaSemanaSelect
                            htmlFor="diaDaSemanaEdit"
                            inputDiaDaSemanaErrorClass = {this.state.inputDiaDaSemanaErrorClass}
                            name="diaDaSemana"
                            diaDaSemana = {this.state.diaDaSemana}
                            handleChange = {this.handleChange}
                            errorDiaDaSemanaMessage = {this.state.errorDiaDaSemanaMessage}
                            disabled={this.props.disabled}

                        />
                    </div>
            )
        }

        const renderDiasDoMes = () => {
            if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue)
            return(
                <div>
                    <DiasDoMesMultiSelect
                        htmlFor="InputDiasDoMesEdit"
                        inputDiasDoMesClass = {this.state.inputDiasDoMesClass}
                        diasDoMes = {this.state.diasDoMes}
                        name="diasDoMes"
                        handleChange = {this.handleChange}
                        errorDiasDoMesMessage = {this.state.errorDiasDoMesMessage}
                        disabled={this.props.disabled}
                    />
                </div>
            )
        }

        
        const renderContaBancariaInput = () => {
            if( this.state.natureza  === this.tiposDeRecebimentoService.pixLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.debitoLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.creditoLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.estornoTarifaLabel
            ){
              return (
            <>
            <div className="p-field ">
                <InputChooseContaBancaria
                    excludeFundosFixos
                    excludeAplicacaoFinanceira
                    searchContaBancariaById={true}
                    contaBancariaId={this.state.contaBancariaId}
                    selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                    handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                    inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                    errorContaBancariaMessage={this.state.errorContaBancariaMessage}
                    noHTMLFormat={true}
                    disabled={this.props.disabled}
                />
            </div>
            
            <br />

            </>
              )
            }
        }

        const renderContaContabilInput = () => {
            return (
                <ContaContabilViewInsideDialog
                    contaContabil={this.state.selectedContaContabil}
                    // handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    // inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    push={this.props.push}
                    disabled
                
                />
                    
            )
        }

        return (
            <>
            <Dialog visible={this.props.visible}
                    style={{ width: '30vw' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={editTipoRecebimentoDialogFooter}
                    onHide={this.hideDialog}>

                <div className="p-field">
                    <label htmlFor="prazoEdit">N° de Chamada </label>
                    <input style={{color: '#000'}}   className={"form-control " + this.state.inputNumeroDeChamadaErrorClass}
                        id="numeroDeChamada"
                        name="numeroDeChamada"
                        value={this.state.numeroDeChamada}
                        placeholder="Digite o n° de chamada"
                        onChange = {this.handleChange}
                        // disabled={this.props.disabled}
                        disabled
                    />
                    <div className="invalid-feedback">{this.state.errornNumeroDeChamadaMessage}</div>
                </div>                        

                <br />

                <div className="p-field">
                    <label htmlFor="nomeTipoDeRecebimento">Nome</label>
                    <input style={{color: '#000'}} type="text"
                            className={"form-control " + this.state.inputNomeErrorClass}
                            value = {this.state.nomeTipoDeRecebimento}
                            name="nomeTipoDeRecebimento"
                            onChange={this.handleChange}
                            id="inputnomeTipoDeRecebimento"
                            placeholder="Digite o nome"
                            disabled={this.props.disabled}
                            />
                    <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div>

                <br />
                <div className="p-field">
                    <label htmlFor="natureza">Natureza</label>
                    <input style={{color: '#000'}} type="text"
                            className="form-control "
                            value = {this.state.natureza}
                            name="natureza"
                            id="inputNatureza"
                            disabled
                            />
                </div>
               
                {renderDialogOptionsIfNotBoleto()}
                
                {renderDiaDaSemana()}

                {renderDiasDoMes()}

                {renderContaBancariaInput()}

                {renderContaContabilInput()}

                <div className = "col-md-12">
                <CheckBoxComponent
                    label={ConstantsUtil.destacadoNoDRECheckBoxLabel}
                    checked={this.state.destacadoDRE}
                    handleCheckBoxChange={(destacadoDRE) => this.setState({destacadoDRE})}
                />
                </div>

            </Dialog>
            
            </>

        )
    }
}

export default EditTipoDeRecebimentoDialog