import React from 'react'
import LancamentoFechamentoCaixaService from '../../app/service/contasAReceber/lancamentoFechamentoCaixaService';
import PDVCaixaService from '../../app/service/contasAReceber/pdvCaixaService';
import HandleErrorService from '../../app/service/handleErrorService';
import SelectMenu from '../selectMenu';

class PDVCaixaInput extends React.Component {

    constructor(){
        super();
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService();
        this.pdvCaixaService = new PDVCaixaService();
    }

    state = {
        pdvCaixaList: [],
    }

    componentDidMount(){
        this.searchPdvCaixa()
    }

    searchPdvCaixa = () => {
        this.pdvCaixaService.search()
        .then(response => {
            this.setState({pdvCaixaList: this.lancamentoFechamentoCaixaService.formatArray(response.data) })
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    render() {

        return (
            <>
            <label htmlFor="pdvCaixa">Ponto de Venda (Caixa)</label>
            <SelectMenu
                ref={this.props.ref}
                className={"form-control " + this.props.inputPdvCaixaErrorClass}
                name="selectedPdvCaixaId"
                list={this.state.pdvCaixaList} 
                value={this.props.selectedPdvCaixaId}
                onChange={this.props.handleChange}
                disabled={this.props.disabled}
            /> 
            <div className="invalid-feedback">Informe o Caixa</div>  

            </>
        )
    }

}

export default PDVCaixaInput