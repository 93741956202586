import React from 'react'
import TooltipButton from './tooltipButton'
import AnotacoesDialog from './anotacoesDialog'
import AnotacaoService from '../app/service/central/anotacaoService'
import HandleErrorService from '../app/service/handleErrorService'
import AuthService from '../app/service/authService'

class AnotacoesComponent extends React.Component {

    constructor(){
        super()
        this.anotacaoService = new AnotacaoService()
    }

    state = {
        displayAnotacoesViewDialog: false,
        isCreateAnotacaoMode: false,
        anotacoes: [],
    }

    componentDidMount(){
        this.init()
    }

    init = () => {

        if(this.props.anotacoes){
            this.setState({anotacoes: this.props.anotacoes})
        }
        else{
            this.searchAnotacoes()
        }

    }

    searchAnotacoes = () => {
        this.anotacaoService.search(this.props.recurso)
        .then(response => {
            this.setState({anotacoes: response.data.anotacoesList})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            AuthService.stopBlockUi()
        })
    }

    hideAnotacoesViewDialog = () => {
        this.setState({displayAnotacoesViewDialog: false})
        if(this.props.hideAnotacoesViewDialog){
            this.props.hideAnotacoesViewDialog()
        }
    }

    hideAdicionarAnotacaoDialog = () => {
        if(this.state.isCreateAnotacaoMode){
            this.setState({displayAnotacoesViewDialog: false})
            this.setState({isCreateAnotacaoMode: false})
        }
        if(this.props.hideAdicionarAnotacaoDialog)   {
            this.props.hideAdicionarAnotacaoDialog()
        }
    }

    openAnotacaoViewDialog = () => {
        this.setState({displayAnotacoesViewDialog: true})
    }

    openAdicionarAnotacaoDialog = () => {
        this.setState({displayAnotacoesViewDialog: true})
        this.setState({isCreateAnotacaoMode: true})
    }

    research = () => {
        if(this.props.research){
            this.props.research()
            return;
        }

        this.searchAnotacoes()

    }

    render(){

        const renderDialog = () => {
            return (
                <AnotacoesDialog
                    visible={this.state.displayAnotacoesViewDialog}
                    hideAnotacoesViewDialog = {this.hideAnotacoesViewDialog}
                    hideAdicionarAnotacaoDialog = {this.hideAdicionarAnotacaoDialog}
                    anotacoes={this.state.anotacoes}
                    recurso={this.props.recurso}
                    research={this.research}
                    creationMode={this.state.isCreateAnotacaoMode}
                />                
            )            
        }

        return(
            <>
            
                <TooltipButton
                    tooltip="Ver Anotações"
                    icon="pi pi-eye"
                    onClick={() => this.openAnotacaoViewDialog()}
                    disabled={!this.state.anotacoes || this.state.anotacoes.length == 0}
                />
                <TooltipButton
                    tooltip="Adicionar Anotação"
                    style={{marginLeft: '5px'}}
                    icon="pi pi-plus"
                    onClick={() => this.openAdicionarAnotacaoDialog()}
                    disabled={this.props.disabled}
                />

                {renderDialog()}

            </>
        )
    }

}

export default AnotacoesComponent