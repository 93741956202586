import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import { classNames } from 'primereact/utils'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DREPagamentoTable extends React.Component {

    state = {
        
        loading: false,
        displayFichaBancariaDialog: false,
        elementToView: null,
        expanded: false,

    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.tableFilters = new TableFilters();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();

    }

    getTableHeaders = () => {
        
        const periodo = this.props.DRE ? this.props.DRE.initialDate + " - " + this.props.DRE.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData) => {
                
        // var fichaBancaria = rowData.fichaBancariaUnificada
        // fichaBancaria.initialDate = this.props.DRE ? this.props.DRE.initialDate : ''
        // fichaBancaria.finalDate = this.props.DRE ? this.props.DRE.finalDate : ''

        // await this.setState({fichaBancariaToView: JSON.parse(JSON.stringify(fichaBancaria))})

        this.setState({elementToView: rowData})
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateDialog: true})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        // this.setState({fichaBancariaToView: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    formattedDREMensalPagamentoToExportByExcel(){
        var formattedList = []

        this.props.DRE.drePagamentoList.forEach(item =>{
            var obj = {
                Pagamento: item.nome,
            }
            item.dreGrupoContaBancariaList.forEach(contaBancariaColumn => {
                const nomeBanco = contaBancariaColumn.contaBancaria.formattedName
                var obj2 = { [nomeBanco]: contaBancariaColumn.valorTotal}
                Object.assign(obj, obj2);
            })
            obj.Total = item.total
            const porcentagem = '%'
            obj[porcentagem] = `${item.porcentagem}%`
            formattedList.push(obj)
        })

        return formattedList
    }

    formattedFichaBancariaToExport = (list, workbook) => {
        list.forEach(drePagamento => {
            if(drePagamento.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                this.props.DRE.contaBancariaList.forEach(contaBancaria => {
                    //as funções biblioteca xlsx exigem que o nome do sheet não exceda 31 caracteres.
                    var newSheetName = (drePagamento.nome + " - " + contaBancaria.nomeBanco).slice(0, 31)
                    var fichaBancaria = drePagamento[contaBancaria.id].fichaBancaria.fichaBancariaList
                    if(fichaBancaria.length !== 0){
                        var formattedFichaBancaria = fichaBancaria.map(lancamento => {
                            const {data, descricao, saida, saldo, historico} = lancamento
                            return {data, descricao, saida, saldo, historico}
                        })
                    
                        // console.log("formatted: ", formattedFichaBancaria)
                        // console.log("ficha bancaria: ", fichaBancaria)
                        var newWorksheet = GeneralServices.jsonToSheet(formattedFichaBancaria)
                        var newSheet = {[newSheetName]: newWorksheet}
                        Object.assign(workbook.Sheets, JSON.parse(JSON.stringify(newSheet)));
                        workbook.SheetNames.push(newSheetName)
                    }
                })
            }
        })
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalPagamentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.DRE.initialDate + " - " + this.props.DRE.finalDate
            const worksheet = this.generateWorkSheet()
            const sheetName = periodo
            var workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            this.formattedFichaBancariaToExport(this.props.DRE.drePagamentoList, workbook)
            this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Pagamento - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        if(!this.state.expanded && this.props.DRE){
            let listToBeReturned = this.props.DRE.drePagamentoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }

        return this.props.DRE.drePagamentoList
    }    

    render (){

        const renderContasBancariasColumn = () => {
            var contasBancariasColumnList = []

            if(this.props.DRE){
                this.props.DRE.contaBancariaList.forEach(contaBancaria => {
                    contasBancariasColumnList.push(renderColumnItem(contaBancaria))
                });
            }


            return contasBancariasColumnList
        }

        const renderColumnItem = (contaBancaria) => {
            return (
                <Column
                    field={contaBancaria.nomeBanco}
                    header={contaBancaria.nomeBanco}
                    style ={ {width: '300px'} }
                    body={rowData => renderColumn(rowData, contaBancaria)}
                    // sortable
                />
            )
        }

        const renderColumn = (rowData, contaBancaria) => {

        //     const valor = GeneralServices.showFormattedIfNotNull(rowData[contaBancaria.id].valor, true)

        //     return(
        //         <>
        //         <div className='row'>
        //         <div className='col-md-6'>
        //         {valor}
        //         </div>

        //         {renderViewLancamentosButton(rowData, contaBancaria, valor)}
                
        //         </div>
        //         </>
        //     )
        }

        const renderTotalColumn = (rowData) => {
            const valor = GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)
            const valorNaoFormatado = rowData.showTotal

            return(
                <div className='right-align'>
                    {renderViewLancamentosLink(rowData, valor, valorNaoFormatado)}
                </div>
            )
        }

        const renderViewLancamentosLink = (rowData, valor, valorNaoFormatado) => {
            // const cond1 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
            const cond1 = rowData.total !== 0
            const cond2 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){
                const classes = classNames({
                    // 'superavitValue': valorNaoFormatado >= 0,
                    'deficitValue': valorNaoFormatado < 0,
                    'linkValue': true
                    
                });                
                return(
                    <a 
                        className={classes}
                        // href='/DRE'
                        onClick={() => this.viewLancamentos(rowData)}
                    >
                        {valor}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {valor}
                    </>
                )
            }
        }

        
        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            '200px',
            "Nome"
        )

        const totalFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedTotal,
            this.filterList(),
            "selectedTotal",
            (name, value) => this.setState({[name]: value}),
            'total',
            '200px',
            "Total"
        )

        const porcentagemFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedPorcentagem,
            this.filterList(),
            "selectedPorcentagem",
            (name, value) => this.setState({[name]: value}),
            'porcentagem',
            '200px',
            "Porcentagem"
        )        

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    // className="p-datatable-responsive-demo"
                    rowClassName={this.rowClass}
                    autoLayout
                    rowHover
                    showGridlines
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw'}}
                    scrollHeight="500px"
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.DRE && this.props.DRE.drePagamentoList ? this.props.DRE.drePagamentoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Grupos de Pagamento"
                >

                <Column field="nome" header="Nome"
                    style ={ {minWidth: '200px'} }
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    body = {rowData => rowData.showNome}
                />

                {/* {renderContasBancariasColumn()} */}

                <Column field="total" header="Total"
                    style ={ {minWidth: '200px'} }
                    filter={this.state.expanded} filterElement={totalFilterElement}
                    body = {renderTotalColumn}
                />

                <Column field="porcentagem" header="%"
                    style ={ {minWidth: '200px'} }
                    filter={this.state.expanded} filterElement={porcentagemFilterElement}
                    body = {rowData => <div className='right-align'>{`${rowData.showPorcentagem}%`}</div> }
                />

                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded)
                return (
                    <Button
                        label="Exportar"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font"
                        onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '15px'}}
                    />            
            )
        }

        return (
            <>

            <div className = "card-header header-small-height">
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            await this.setState({selectedTotal: ''})
                            await this.setState({selectedPorcentagem: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />                    
                    <div className='col-md-4'>
                        <h5
                            className='small-font-size'
                        >Pagamento</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                
            </div>
            </div>

            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.props.DRE ? this.props.DRE.initialDate : ''}
                finalDate={this.props.DRE ? this.props.DRE.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // fichaBancaria={this.state.fichaBancariaToView}
                search={this.props.search}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
            />

            </>
        )
    }


}  

export default DREPagamentoTable