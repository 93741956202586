import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import * as popUp from '../toastr'
import TableFilters from '../tableFilters'

class BoletoRecebimentoTable extends React.Component {

    constructor(){
        super()
        this.tableFilters = new TableFilters()
        this.dt = React.createRef()
    }

    state = {

    }

    onValueChange = (currentList) => {
        if(this.props.onValueChange){
            this.props.onValueChange(currentList)
        }
    }

    render() {

        const dataEmissaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataEmissao,
            this.props.list,
            "selectedDataEmissao",
            (name, value) => this.setState({[name]: value}),
            'dataEmissao',
            '100%',
            "Data de Emissão"
        )

        const dataVencimentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataVencimento,
            this.props.list,
            "selectedDataVencimento",
            (name, value) => this.setState({[name]: value}),
            'dataVencimento',
            '100%',
            "Data de Vencimento"
        )

        const numeroDocumentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedBumeroDocumento,
            this.props.list,
            "selectedBumeroDocumento",
            (name, value) => this.setState({[name]: value}),
            'numeroDocumento',
            '100%',
            "N° documento"
        )

        const valorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValor,
            this.props.list,
            "selectedValor",
            (name, value) => this.setState({[name]: value}),
            'valor',
            '100%',
            "Valor"
        )

        const nomePagadorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomePagador,
            this.props.list,
            "selectedNomePagador",
            (name, value) => this.setState({[name]: value}),
            'nomePagador',
            '100%',
            "Nome do Pagador"
        )

        const tipoDeRecebimentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoDeRecebimento,
            this.props.list,
            "selectedTipoDeRecebimento",
            (name, value) => this.setState({[name]: value}),
            'tipoDeRecebimento.nomeTipoDeRecebimento',
            '100%',
            "Tipo de Recebimento"
        )

        const cnpjPagadorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCnpjPagador,
            this.props.list,
            "selectedCnpjPagador",
            (name, value) => this.setState({[name]: value}),
            'cpjOuCnpjPagador',
            '100%',
            "CPF/CNPJ do Pagador"
        )

        return(
            <DataTable
                ref={this.dt}
                value={this.props.list}
                className="p-datatable-sm small-font"
                // rowClassName={this.rowClass}
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                onValueChange={this.onValueChange}
                scrollable
                scrollHeight={ '350px' }
                loading={this.props.loading || this.state.loading}
                dataKey="numeroIdentificacaoBoleto"
                emptyMessage="Nenhum Lançamento encontrado."
                paginator
                // rows={ConstantsUtil.initialTableQuantityOption}
                rows={this.props.list.length}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(this.props.list.length, this.props.list ? this.props.list.length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

                <Column
                    header='Data de Emissão'
                    field='dataEmissao'
                    sortable
                    filter filterElement={dataEmissaoFilterElement}
                />

                <Column
                    header='Data de Vencimento'
                    field='dataVencimento'
                    sortable
                    filter filterElement={dataVencimentoFilterElement}
                />

                <Column
                    header='N° documento'
                    field='numeroDocumento'
                    sortable
                    filter filterElement={numeroDocumentoFilterElement}
                />                

                <Column
                    header='Valor'
                    field='valor'
                    sortable
                    filter filterElement={valorFilterElement}
                    body = {rowData => <div className='right-align'> {GeneralServices.showFormattedIfNonZero(rowData.valor, true)} </div> }
                />

                <Column
                    header='Nome do Pagador'
                    field='nomePagador'
                    sortable
                    filter filterElement={nomePagadorFilterElement}
                />

                <Column
                    header='Tipo de Recebimento'
                    field='tipoDeRecebimento.nomeTipoDeRecebimento'
                    sortable
                    filter filterElement={tipoDeRecebimentoFilterElement}
                />

                <Column
                    header='CPF/CNPJ do Pagador'
                    field='cpjOuCnpjPagador'
                    sortable
                    filter filterElement={cnpjPagadorFilterElement}
                    body={rowData => GeneralServices.formataCpfCnpj(rowData.cpjOuCnpjPagador)}
                />


            </DataTable>
        )
    }

}

export default BoletoRecebimentoTable