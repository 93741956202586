import React from 'react'
import Card from '../../../components/card'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../../main/authProvider'
import DRECompetenciaMensalTable from '../../../components/relatorios/DRECompetencia/DRECompetenciaMensal/DRECompetenciaMensalTable'
import DRECompetenciaService from '../../../app/service/relatorios/DRECompetenciaService'
import HandleErrorService from '../../../app/service/handleErrorService'
import { InputSwitch } from 'primereact/inputswitch'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../../context/constantsUtil'
import { Button } from 'primereact/button'
import GeneralServices from '../../../app/service/generalServices'
import EmpresasMultiSelect from '../../../components/empresas/empresasMultiSelect'
import FormGroup from '../../../components/form-group'
import CentralService from '../../../app/service/central/centralService'
import AuthService from '../../../app/service/authService'

const nomeLabel = "Nome"
const valorLabel = "Valor"
const porcentagemLabel = "Porcentagem"
class DRECompetenciaMensal extends React.Component {

    constructor(){
        super()
        this.dreCompetenciaService = new DRECompetenciaService()
        this.centralService = new CentralService()
    }

    state = {
        dreCompetenciaMensal: '',

        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',
        
        unificar: '',
        selectedEmpresas: [],
        inputEmpresasErrorClass: '',
        errorEmpresasErrorMessage: '',        

        loading: false,
        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,
    }

    // componentDidMount(){
    // }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    handleUnificarChange = (e) => {
        this.setState({unificar: e.value})
        if(!e.value){
            this.setState({selectedEmpresas: []})
        }
    }    

    toggleButton = () => {
        if(!this.state.habilitaBusca){
            //Clicou em "Voltar"
            this.setState({dreCompetenciaMensal: ''})
        }
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    resetView = () => {

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.unificar){
            if(!this.state.selectedEmpresas || this.state.selectedEmpresas.length === 0){
                this.setState({inputEmpresasErrorClass: ConstantsUtil.primereactInputErrorClass})
                this.setState({errorEmpresasErrorMessage: ConstantsUtil.errorSelecioneEmpresasMessage})
                check = false
            }
        }

        return check
    }
    
    callSearch = () => {
        this.resetView()

        if(this.checkData()){
            this.search()
        }
    }

    getDRECompetenciaMensal = () => {
        if(this.state.unificar){
            return this.centralService.getDRECompetenciaMensalUnificado(this.state.initialDate, this.state.finalDate, this.state.selectedEmpresas)
        }
        else{
            return this.dreCompetenciaService.getDRECompetenciaMensal(this.state.initialDate, this.state.finalDate)
        }
    }

    research = () => {
        this.toggleButton()
        this.search()
    }

    search = () => {
        this.setState({loading: true})
        
        this.toggleButton()

        this.setState({isSearchingLancamentos: true})
        this.setState({isFinishedSearch: false})

        this.getDRECompetenciaMensal()
        .then(async response => {
            await this.setState({dreCompetenciaMensal: response.data})
            // console.log("dreCompetenciaMensal: ", this.state.dreCompetenciaMensal.dreCompetenciaGrupoList[0])
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
            this.setState({isSearchingLancamentos: false})
            this.setState({isFinishedSearch: true})
        })
    }

    formattedDREMensalToExportToExcel = () => {
        // console.log("this.state.dreCompetenciaMensal.dreCompetenciaGrupoList: ", this.state.dreCompetenciaMensal.dreCompetenciaGrupoList)
        let formattedListToExport = []
        this.state.dreCompetenciaMensal.dreCompetenciaGrupoList.forEach(dreCompetenciaGrupo => {
            let row =
            {
                [nomeLabel]: dreCompetenciaGrupo.titulo,
                [valorLabel]: `R$ ${GeneralServices.valueBodyTemplate(dreCompetenciaGrupo.total)}`,
                [porcentagemLabel]: `${dreCompetenciaGrupo.porcentagem}%`
            }
            formattedListToExport.push(row)
        })
        return formattedListToExport
    }

    generateWorkSheetGeral = () => {
        let formattedList = this.formattedDREMensalToExportToExcel()
        let worksheet = GeneralServices.jsonToSheet(formattedList);
        return worksheet
    }

    formattedGrupoExportToExcel = dreCompetenciaGrupo => {
        let formattedListToExport = []
        // console.log("dreCompetenciaGrupo.dreCompetenciaItemList: ", dreCompetenciaGrupo.dreCompetenciaItemList)
        if(!dreCompetenciaGrupo.dreCompetenciaItemList || dreCompetenciaGrupo.dreCompetenciaItemList.length === 0){
            return null
        }
        dreCompetenciaGrupo.dreCompetenciaItemList.forEach(dreCompetenciaItem => {
            let row =
            {
                [nomeLabel]: dreCompetenciaItem.nome,
                [valorLabel]: `R$ ${GeneralServices.valueBodyTemplate(GeneralServices.formatTo2CasasDecimais(dreCompetenciaItem.valor))}`,
                [porcentagemLabel]: `${GeneralServices.formatTo2CasasDecimais(dreCompetenciaItem.porcentagem)}%`
            }
            formattedListToExport.push(row)
        })
        return formattedListToExport
    }

    generateWorkbookToGrupo = (dreCompetenciaGrupo) => {
        let formattedList = this.formattedGrupoExportToExcel(dreCompetenciaGrupo)
        if(!formattedList){
            return null
        }
        let grupoWorksheet = GeneralServices.jsonToSheet(formattedList);
        const sheetName = dreCompetenciaGrupo.titulo
        let grupoWorkbook = { Sheets: { [sheetName]: grupoWorksheet }, SheetNames: [[sheetName]] };
        return grupoWorkbook
    }

    getFilenameToExport = () => {
        const periodo = this.state.initialDate + " - " + this.state.finalDate
        return `DRE Por Competência Mensal - ${periodo} - Beppsys`
    }

    exportPDF = () => {
        let columns = [
            {field: [nomeLabel], header: [nomeLabel]},
            {field: [valorLabel], header: [valorLabel]},
            {field: [porcentagemLabel], header: [porcentagemLabel]},
        ]
        let exportColumns = columns.map(col => ({ title: col.header, dataKey: col.field }))

        let exportList = this.formattedDREMensalToExportToExcel()
        this.createPDFFIle(this.getFilenameToExport(), exportColumns, exportList)
    }

    createPDFFIle = (filename, exportColumns, list) => {
        // var exportColumns = this.state.columns.map(col => ({ title: col.header, dataKey: col.field }))
        // var list = this.props.notFilteredList

        const periodoLabel = `Período: de ${this.state.initialDate.replaceAll("-", "/")} a ${this.state.finalDate.replaceAll("-", "/")}`
        
        const empresas = (this.state.unificar ? this.state.selectedEmpresas : [{nome: AuthService.getEmpresa()}]).map(item => item.nome).join(", ")
        const empresasLabel = `Empresa(s) selecionada(s): ${empresas}`

        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                // Adicionar título
                doc.setFontSize(20);
                const titulo = 'DRE Por Competência - Bepp';
                const tituloWidth = doc.getTextWidth(titulo);
                const tituloX = (doc.internal.pageSize.width - tituloWidth) / 2;
                doc.text(titulo, tituloX, 15);                

                // Adicionar linhas de texto
                doc.setFontSize(12);
                doc.text(periodoLabel, 10, 25);
                doc.text(empresasLabel, 10, 30);
                // doc.text('Data: ' + new Date().toLocaleDateString(), 10, 15);
                // doc.text('Total de registros: ' + list.length, 10, 20);                

                doc.autoTable(exportColumns, list, { startY: 40 });
                
                doc.save(filename);
            })
        })
    }

    exportExcel = () => {
        const worksheet = this.generateWorkSheetGeral()
        const sheetName = "Geral"
        
        let workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };

        this.state.dreCompetenciaMensal.dreCompetenciaGrupoList.forEach(dreCompetenciaGrupo => {
            let grupoWorkbook = this.generateWorkbookToGrupo(dreCompetenciaGrupo)
            if(grupoWorkbook){
                Object.assign(workbook.Sheets, grupoWorkbook.Sheets)
                workbook.SheetNames.push(grupoWorkbook.SheetNames)
            }
        })

        GeneralServices.saveAsExcelFile(workbook, this.getFilenameToExport());
    }

    render(){

        const renderCalendarFilters = () => {
            return (
                <>
                <div className = "col-md-12">
                    <div className="row">
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        label="Escolher período"
                        defaultValue={ConstantsUtil.calendarMesCompletoFilterLabel}
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                    />
                    {/* </div>

                    <br />

                    <div className="row"> */}
                    <div
                        style={{
                            marginTop: '0.3rem', marginLeft: '0.5rem', marginRight: '0.5rem'
                        }}
                        className = "col-md-1.5"
                    >
                        <h6>Unificar Empresas?</h6>
                        <InputSwitch 
                            checked={this.state.unificar}
                            onChange={this.handleUnificarChange}
                            disabled={!this.state.habilitaBusca}
                        />
                    </div>

                    {renderEmpresasMultiSelect()}

                    {/* <div className = "col-md-8">
                    </div> */}

                    </div>
                </div>
                </>
            )
        }

        const renderEmpresasMultiSelect = () => {
            if(this.state.unificar){
                return (
                    <div className = "col-md-1">
                    <FormGroup label = "Empresas" >
                    <EmpresasMultiSelect
                        handleChange={(selectedEmpresas) => this.setState({selectedEmpresas})}
                        placeholder="Selecione as empresas para unificar"
                        disabled={!this.state.habilitaBusca}
                        inputErrorClass={this.state.inputEmpresasErrorClass}
                        errorMessage={this.state.errorEmpresasErrorMessage}
                    />
                    </FormGroup>
                    </div>
                )
            }
        }          
        
        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        className='small-button'
                        label="Gerar DRE"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button
                        label={this.state.isSearchingLancamentos ? "Buscando..." : "Voltar"}
                        icon="pi pi-undo"
                        className={this.state.isSearchingLancamentos ? "small-button" : "p-button-danger small-button"}
                        onClick = {this.toggleButton}
                        disabled = {this.state.isSearchingLancamentos}
                    />
                    <Button
                        label="Exportar"
                        icon="pi pi-file-excel"
                        className="p-button-success small-button"
                        onClick={this.exportExcel}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        style ={{marginLeft: '10px'}}
                        tooltipOptions={{position: 'top'}}
                        disabled = {this.state.isSearchingLancamentos}
                    />
                    <Button
                        label="Exportar"
                        icon="pi pi-file-pdf"
                        className="p-button-danger small-font small-button"
                        onClick={this.exportPDF}
                        style={{marginLeft: '10px'}}
                        tooltip={ConstantsUtil.exportPdfLabel}
                        tooltipOptions={{position: 'top'}}
                        disabled = {this.state.isSearchingLancamentos}
                    />                    
                </>
            )
        }        

        const renderTables = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading ){
                let tableList = []
                if(this.state.dreCompetenciaMensal && this.state.dreCompetenciaMensal.dreCompetenciaGrupoList){
                    this.state.dreCompetenciaMensal.dreCompetenciaGrupoList.forEach(dreCompetenciaGrupo => {
                        tableList.push(renderTable(dreCompetenciaGrupo))
                    });
                    return tableList
                }
            }
        }

        const renderTable = (dreCompetenciaGrupo) => {
            return (
                <div className="" 
                    style={{paddingBottom: ConstantsUtil.paddingBeetwenTablesDREConsolidado}}
                >
                    <DRECompetenciaMensalTable
                        selectedEmpresas={this.state.selectedEmpresas}
                        loading={this.state.loading}
                        dataInicial = {this.state.dreCompetenciaMensal.dataInicial}
                        dataFinal = {this.state.dreCompetenciaMensal.dataFinal}
                        dreCompetenciaGrupoMensal={dreCompetenciaGrupo}
                        unificar={this.state.unificar}
                        searchAllDRE={this.research}
                    />
                </div>
            )            
        }

        return (
            <div className="bs-docs-section " >
            <Card title = "DRE Por Competência Mensal">
                <div className = "col-md-12">
                    
                <div className="row">

                    {renderCalendarFilters()}

                </div>  

                <div style={{marginBottom: '0.8rem'}}>
                    {renderButtons()}
                </div>


                {/* <br /> */}

                <div className='visaorj-small'>
                    {renderTables()}
                </div>

                </div>  

            </Card>
            <div className="d-flex "/>
        </div>                
        )
    }


}

DRECompetenciaMensal.contextType = AuthContext

export default withRouter(DRECompetenciaMensal)