import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import RelatorioFechamentoCaixaService from '../../../app/service/relatorios/relatorioFechamentoCaixaService'
import HandleErrorService from '../../../app/service/handleErrorService'

const nomeWidth = '300px'
class RelatorioFechamentoCaixaTable extends React.Component {

    state = {
        
        loading: false,
        relatorio: {},

    }

    constructor(){
        super();
        this.relatorioFechamentoCaixaService = new RelatorioFechamentoCaixaService()

    }

    componentDidMount(){
        this.search()
    }

    search = () => {

        this.setState({loading: true})

        let extraHeaderObj= null
        // console.log("relatFecCxTable: ", this.props.specificEmpresaId)
        if(this.props.specificEmpresaId) {
            extraHeaderObj = {specificEmpresaId: this.props.specificEmpresaId}
        }
        
        this.relatorioFechamentoCaixaService.getRelatorioFechamentoCaixaByDateInterval(
            {
                dataInicial: this.props.dataInicial,
                dataFinal: this.props.dataFinal,
                especificar: this.props.especificar,
                pdvCaixaId: this.props.pdvCaixaId,
                turno: this.props.turno,
            },
            extraHeaderObj
        )
        .then(async response => {
            await this.setState({relatorio: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    getTableHeaders = () => {
        
        const periodo = this.props.dataInicial ? this.props.dataInicial + " - " + this.props.dataFinal : ""
        const periodoLabel = "Período: " + periodo

        const caixa = this.state.relatorio.caixa ? this.state.relatorio.caixa : ''
        const caixaLabel = "Caixa: " + caixa

        const turno = this.state.relatorio.turno ? this.state.relatorio.turno : ''
        const turnoLabel = "Turno: " + turno

        return {periodo: periodoLabel, caixa: caixaLabel , turno: turnoLabel}
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.tipoTotaisFichaBancariaLabel,
            [ConstantsUtil.pdvRelatorioFechmanetoCaixaTableClass]: 
                rowData.tipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.pdvTableUpperCaseLabel
                ||rowData.tipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.repiqueTableUpperCaseLabel,
            // [ConstantsUtil.diferencaRelatorioFechmanetoCaixaTableClass]:  rowData.tipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.diferencaTableUpperCaseLabel
        }

        return obj
    }

    render (){

        const renderDiasColumn = () => {
            var diasColumnList = []

            if(this.state.relatorio && this.state.relatorio.dates && this.state.relatorio.dates.length !== 0){
                this.state.relatorio.dates.forEach(dia => {
                    diasColumnList.push(renderColumnItem(dia))
                });
            }


            return diasColumnList
        }

        const renderColumnItem = (dia) => {
            return (
                <Column
                    field={dia}
                    header={dia}
                    style ={ {width: ConstantsUtil.widthColumRelatorioFechamentoCaixa} }
                    body = { (relatorioFechamentoCaixaItem, rowIndex) => GeneralServices.renderBodyColumnInOneLine(
                        GeneralServices.showFormattedIfNotNull(
                            relatorioFechamentoCaixaItem.diaValorMap[dia],
                            true,
                            (
                                relatorioFechamentoCaixaItem.tipoDeRecebimento.nomeTipoDeRecebimento !== ConstantsUtil.totaisTableUpperCaseLabel
                                && relatorioFechamentoCaixaItem.tipoDeRecebimento.nomeTipoDeRecebimento !== ConstantsUtil.pdvTableUpperCaseLabel
                                && relatorioFechamentoCaixaItem.tipoDeRecebimento.nomeTipoDeRecebimento !== ConstantsUtil.repiqueTableUpperCaseLabel
                            )
                        ),
                        rowIndex,
                        dia
                    )}
                />
            )
        }

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().caixa}</h5>            
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().turno}</h5>            
                    </div>
                </div>
                
            </div>
            </div>

            <DataTable
                ref={this.dt}
                value={this.state.relatorio.relatorioList}
                className="p-datatable-sm small-font "
                // className="p-datatable-responsive-demo"
                rowClassName={this.rowClass}
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                // style={{ maxWidth: '100vw' }}
                scrollable
                scrollHeight={this.props.scrollHeight ? this.props.scrollHeight : "500px"}
                frozenWidth={nomeWidth}
                loading={this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Tipo de Recebimento encontrado."
                paginator={!this.props.hidePaginator}
                rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.state.relatorio && this.state.relatorio.relatorioList ? this.state.relatorio.relatorioList.length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Recebimento"
            >

            <Column field="tipoDeRecebimento.nomeTipoDeRecebimento" header="Tipo de Recebimento"
                style ={ {width: nomeWidth} }
                frozen
                headerStyle={{ width: nomeWidth }}
                body = { (rowData, rowIndex) => {
                    const uniqueId = `${rowIndex.rowIndex}`;
                    return (
                        <div
                            style ={ {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            } }
                            title={rowData.tipoDeRecebimento.nomeTipoDeRecebimento}
                        >
                        <span id={uniqueId}>{rowData.tipoDeRecebimento.nomeTipoDeRecebimento}</span>

                        </div>                        
                    )
                }}
            />

            {renderDiasColumn()}


            <Column field="total" header="Total"
                style ={ {width: ConstantsUtil.widthColumRelatorioFechamentoCaixa} }
                body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.total, true)}
            />

            </DataTable>

            </>
        )
    }


}  

export default RelatorioFechamentoCaixaTable