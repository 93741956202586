import React from 'react'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../main/authProvider'
import { Card } from 'primereact/card';
import logo from './../images/logo4.png'
import BottomBar from '../components/bottomBar';

class MenuHome extends React.Component{

    render() {

        const header = (
            <img alt="logo" src={logo} />
        );

        return(
            <div className="bs-docs-section">
            <div className = "col-md-3 d-flex " 
                style ={{
                    margin: 'auto',

                    //para centralizar verticalmente:
                    position: 'absolute',
                    top: '50%',
                    left:'50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign:'center',
                }}
                >

             <Card title="VisãoRJ" subTitle="Seja Bem-Vindo!" style={{ width: '25em' }}  header={header}>
                    <p className="m-0" style={{lineHeight: '1.5'}}>
                        Beppsys: Seu sistema de Gestão Financeira.
                    </p>
                </Card>
            </div>

            {/* <BottomBar
                loggedInfo
                push={this.props.history.push}
            /> */}
            
            </div>
        )
    }

}

MenuHome.contextType = AuthContext

export default withRouter(MenuHome)