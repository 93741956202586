import React from 'react'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../main/authProvider'
import DadosNFComponent from '../../components/nf/dadosNFComponent';
import { Button } from 'primereact/button';
import Card from '../../components/card';
import NFService from '../../app/service/estoque/NFService';
import * as popUp from '../../components/toastr';
import HandleErrorService from '../../app/service/handleErrorService';
import GeneralServices from '../../app/service/generalServices';
import FormGroup from '../../components/form-group';
import SelectMenu from '../../components/selectMenu';
import TransactionService from '../../app/service/estoque/transactionService';
import ConstantsUtil from '../../context/constantsUtil';
import { InputSwitch } from 'primereact/inputswitch';
import AuthService from '../../app/service/authService';

class LancamentoManualNF extends React.Component {

    constructor(){
        super();
        this.nfService = new NFService()
        this.dadosNFComponentRef = React.createRef()
        this.generalServices = new GeneralServices()
        this.transactionService = new TransactionService()
    }

    state = {
        isSaving: false,

        tipoNF: '',
        inputTipoNFErrorClass: '',
    }

    handleTipoNFChange = async (event) => {
        const value = event.target.value
        // const name = event.target.name

        if(value && this.state.tipoNF){
            /*
            Usuário selecionou uma opção diferente de '' e o valor atual também é diferente de ''.
            Ou seja, trocou de Entrada para Saída ou vice-versa.
            Neste caso, deve forçar uma troca intermediária para '', de forma que todos os componentes da tela sejam
            re-renderizados e tenham seus valores "limpados"
            */
           let middleEvent = {target: {value: ''}}
           await this.handleTipoNFChange(middleEvent)
        }

        await this.setState({ tipoNF: value })
    }

    handleEmprestimoChange = (event) => {
        this.setState({emprestimo: event.value})
        this.handleTipoNFChange({target: {value: this.state.tipoNF}}) //Para "zerar" componentes.
    }

    resetView = () => {
        this.setState({inputTipoNFErrorClass: ''})
    }    

    checkData = () => {

        this.resetView()

        let check = true
        if(!this.state.tipoNF){
            this.setState({inputTipoNFErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        let dadosNFComponentRefCheck = this.dadosNFComponentRef.current.checkData()
        
        check = check && dadosNFComponentRefCheck
        
        return check;
    }

    callSave = () => {
        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        this.setState({isSaving: true})
        let notaFiscalDTO = this.dadosNFComponentRef.current.montaNF()
        
        AuthService.startBlockUi()

        // console.log("notaFiscalDTO: ", notaFiscalDTO)
        
        this.nfService.lancamentoManualNF(notaFiscalDTO)
        .then(async response => {
            popUp.successPopUp("Lançamento salvo com sucesso!")
            await this.generalServices.sleep(1000)
            window.location.reload()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({isSaving: false})
            AuthService.stopBlockUi()
        })
    }

    isNFSaida = () => {
        return this.state.tipoNF === ConstantsUtil.saidaLabel
    }

    render() {

        const renderEmprestimoOption = () => {
            if(this.state.tipoNF){
                return (
                    <>
                        <div
                            className = "col-md-3"
                            style={{marginTop: '5px'}}
                        >
                            <h6>{`${this.isNFSaida() ? "Saída Informal" : "Entrada Informal"}`}</h6>
                            <InputSwitch
                                checked={this.state.emprestimo}
                                onChange={this.handleEmprestimoChange}
                                // disabled={!this.state.habilitaBusca}
                            />
                        </div>
                    </>
                )
            }
        }

        const renderDadosNFComponent = () => {
            if(this.state.tipoNF){
                return (
                    <DadosNFComponent
                        ref={this.dadosNFComponentRef}
                        isNFSaida={this.isNFSaida()}
                        emprestimo={this.state.emprestimo}
                    />
                )
            }
        }

        return (
                
            <>
                <div className="bs-docs-section" >
                
                <Card title = "Lançamento Manual de Nota Fiscal">

                    <div className = "col-md-12">
                    <div className = "row">
                    <div className = "col-md-2">
                        <FormGroup label = "Tipo da Nota Fiscal" htmlFor = "InputType">
                            <SelectMenu
                                className={"form-control " + this.state.inputTipoNFErrorClass}
                                name="tipoNF"
                                list= {this.transactionService.getTypeList()}
                                value={this.state.tipoNF}
                                onChange={this.handleTipoNFChange}
                                />
                            <div className="invalid-feedback">{"Selecione o Tipo da Nota Fiscal"}</div>
                        </FormGroup>
                    </div>
                        {renderEmprestimoOption()}
                    </div>
                    </div>

                    {renderDadosNFComponent()}

                    <br />

                    <div className='col-md-6'>
                        <Button
                            label="Salvar Lançamento"
                            icon="pi pi-save"
                            onClick = {this.callSave}
                            disabled = {this.state.isSaving}
                        />
                    </div>

                </Card>

                <div className="d-flex "/>
                
            </div>            
            </>
              
        )
    }


}

LancamentoManualNF.contextType = AuthContext

export default withRouter(LancamentoManualNF)