import React from 'react'
import { withRouter } from 'react-router-dom'
import Card from '../../components/card'

import { AuthContext } from '../../main/authProvider'

import { Button } from 'primereact/button'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import * as popUp from '../../components/toastr'
import HandleErrorService from '../../app/service/handleErrorService'
import TiposDeRecebimentoTable from './tiposDeRecebimentoTable'
import DiaDaSemanaSelect from '../../components/tipoDeRecebimento/diaDaSemanaSelectComponent'
import DiasDoMesMultiSelect from '../../components/tipoDeRecebimento/diasDoMesMultiSelectComponent'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import PDVCaixaService from '../../app/service/contasAReceber/pdvCaixaService'
import LancamentoFechamentoCaixaService from '../../app/service/contasAReceber/lancamentoFechamentoCaixaService'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import InputChooseContaContabil from '../../components/planoDeContas/inputChooseContaContabil'
import ConstantsUtil from '../../context/constantsUtil'
import GeneralServices from '../../app/service/generalServices'
import CheckBoxComponent from '../../components/relatorios/checkBoxComponent'

class CadastrarTiposDeRecebimento extends React.Component{


    state = {

        natureza: '',
        inputNaturezaErrorClass: '',
        errorNaturezaMessage: 'Natureza é obrigatória',

        nomeTipoDeRecebimento: '',
        inputNomeErrorClass: '',
        errorNomeMessage: '',
        disableNomeTipoDeRecebimento: false,

        prazo: '',
        inputPrazoErrorClass: '',
        errorPrazoMessage: '',
        disablePrazo: false,
        
        taxa: '',
        inputTaxaErrorClass: '',
        errorTaxaMessage: '',
        disableTaxa: false,

        tipoDeCorte: '',
        inputTipoCorteErrorClass: '',
        errorTipoCorteMessage: '',
        disableTipoDeCorte: false,
        
        diaDaSemana: '',
        inputDiaDaSemanaErrorClass: '',
        errorDiaDaSemanaMessage: '',

        diasDoMes: [],
        inputDiasDoMesClass: '',
        errorDiasDoMesMessage: '',

        tiposDeRecebimento: [],

        nomeContaContabil: '',
        inputNomeContaContabilErrorClass: '',
        errorNomeContaContabilMessage: 'Nome da Conta Contábil é obrigatório',
        
        pdvCaixaList: [],
        selectedPdvCaixaId: '',
        inputPdvCaixaErrorClass: '',
        errorPdvCaixaMessage: 'Selecione um PDV(Caixa)',

        destacadoDRE: false,


        loading: false,
        loadingContaBancariaTable: false,

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaErrorClass: '',
        errorContaBancariaMessage:'Selecione uma Conta Bancária',

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass: '',
        errorContaContabilMessage:'Selecione uma Conta Contábil',

    }

    constructor(){
        super()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
        this.contaBancariaService = new ContaBancariaService()
        this.pdvCaixaService = new PDVCaixaService()
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService()
    }

    componentDidMount(){
        this.search()
        this.searchPdvCaixa()
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleTaxaChange = (event) => {
        const value = event.target.value === '' ? '' : parseInt(event.target.value)
        if( value === '' || (value >= 0 && value < 100)){
            this.handleChange(event)
        }
    }

    handlePrazoChange = (event) => {
        const value = event.target.value === '' ? '' : parseInt(event.target.value)
        if( value === '' || (value >= 0)){
            this.handleChange(event)
        }
    }

    handleNaturezaChange = async (event) => {

        this.resetView()
        this.resetInputsOnNaturezaChange()
        
        await this.handleChange(event)

        if(this.state.natureza === ''){
            this.setState({taxa: ''})
            this.setState({prazo: ''})
            this.handleTipoDeCorteChange({target:{name: 'tipoDeCorte', value:''}})
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.dinheiroLabel
            || this.state.natureza === this.tiposDeRecebimentoService.outrosLabel
            || this.state.natureza === this.tiposDeRecebimentoService.estornoTarifaLabel
        ){

            this.setState({taxa: 0})
            this.setState({disableTaxa: true})

            this.setState({prazo: 0})
            this.setState({disablePrazo: true})

            this.setState({tipoDeCorte: this.tiposDeRecebimentoService.diaDoLancamentoValue})
            this.setState({disableTipoDeCorte: true})
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.pixLabel){

            this.setState({taxa: 0})
            this.setState({disableTaxa: true})

            this.setState({prazo: 0})
            this.setState({disablePrazo: true})

            this.setState({tipoDeCorte: this.tiposDeRecebimentoService.diaDoLancamentoValue})
            this.setState({disableTipoDeCorte: true})
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.boletoLabel){

            this.setState({taxa: 0})
            this.setState({disableTaxa: true})

            this.setState({prazo: 0})
            this.setState({disablePrazo: true})

            this.setState({tipoDeCorte: this.tiposDeRecebimentoService.diaDoLancamentoValue})
            this.setState({disableTipoDeCorte: true})
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.debitoLabel){

            this.setState({tipoDeCorte: this.tiposDeRecebimentoService.diaDoLancamentoValue})
            this.setState({disableTipoDeCorte: true})
        }
    }

    handleTipoDeCorteChange = async (event) => {
        this.handleChange(event)
        this.setState({diaDaSemana: null, diasDoMes: null})
    }

    handleSelectionContaBancariaChange = (selectedContaBancaria, selectedContaBancariaLabel) => {
        this.setState({selectedContaBancaria})
        this.setState({selectedContaBancariaLabel})
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})
    }

    resetInputsOnNaturezaChange = () => {

        if(this.state.nomeTipoDeRecebimento === this.tiposDeRecebimentoService.boletoLabel){
            this.setState({nomeTipoDeRecebimento: ''})
        }
        this.setState({disableNomeTipoDeRecebimento: false})

        this.setState({taxa: ''})
        this.setState({disableTaxa: false})

        this.setState({prazo: ''})
        this.setState({disablePrazo: false})

        this.setState({tipoDeCorte: ''})
        this.setState({disableTipoDeCorte: false})

        this.setState({nomeContaContabil: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})

        this.setState({selectedPdvCaixaId: ''})
    }

    resetForm = () => {
        this.resetInputsOnNaturezaChange()
        this.setState({nomeTipoDeRecebimento: ''})
        this.setState({natureza: ''})
        this.setState({diaDaSemana: ''})
        this.setState({diasDoMes: []})
        this.setState({nomeontaContabil: ''})
    }

    resetView = () =>{

        this.setState({inputNaturezaErrorClass: ''})

        this.setState({inputNomeErrorClass: ''})
        this.setState({errorNomeMessage: ''})

        this.setState({inputTaxaErrorClass: ''})
        this.setState({errorTaxaMessage: ''})
        
        this.setState({inputPrazoErrorClass: ''})
        this.setState({errorPrazoMessage: ''})
        
        this.setState({inputTipoCorteErrorClass: ''})
        this.setState({errorTipoCorteMessage: ''})
        
        this.setState({inputDiaDaSemanaErrorClass: ''})
        this.setState({errorDiaDaSemanaMessage: ''})
        
        this.setState({inputDiasDoMesClass: ''})
        this.setState({errorDiasDoMesMessage: ''})

        this.setState({inputNomeContaContabilErrorClass: ''})

        this.setState({inputPdvCaixaErrorClass: ''})

        this.setState({inputContaBancariaErrorClass: ''})

        this.setState({inputContaContabilErrorClass: ''})
    }

    checkData = () => {
        var check = true
        
        if(!this.state.natureza){
            this.setState({inputNaturezaErrorClass: "is-invalid"})
            check = false
        }

        if(!this.state.nomeTipoDeRecebimento){
            this.setState({inputNomeErrorClass: "is-invalid"})
            this.setState({errorNomeMessage:"Nome é obrigatório"})
            check = false
        }

        if(this.state.taxa === ''){
            this.setState({inputTaxaErrorClass: "is-invalid"})
            this.setState({errorTaxaMessage:"Taxa é obrigatória"})
            check = false
        }

        if(this.state.prazo === ''){
            this.setState({inputPrazoErrorClass: "is-invalid"})
            this.setState({errorPrazoMessage:"Prazo é obrigatório"})
            check = false
        }
        
        if(!this.state.tipoDeCorte){
            this.setState({inputTipoCorteErrorClass: "is-invalid"})
            this.setState({errorTipoCorteMessage:"Tipo de corte é obrigatório"})
            check = false
        }

        if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue && !this.state.diaDaSemana){
            this.setState({inputDiaDaSemanaErrorClass: "is-invalid"})
            this.setState({errorDiaDaSemanaMessage:"Dia da semana é obrigatório"})
            check = false
        }

        if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue && (!this.state.diasDoMes || this.state.diasDoMes.length === 0 ) ){
            this.setState({inputDiasDoMesClass: "p-invalid p-d-block"})
            this.setState({errorDiasDoMesMessage:"Dia(s) do mês é(são) obrigatório(s)"})
            check = false
        }

        if(!this.state.nomeContaContabil && this.state.natureza === this.tiposDeRecebimentoService.dinheiroLabel ){
            this.setState({inputNomeContaContabilErrorClass: "is-invalid"})
            check = false
        }

        if(!this.state.selectedPdvCaixaId && this.state.natureza === this.tiposDeRecebimentoService.dinheiroLabel ){
            this.setState({inputPdvCaixaErrorClass: "is-invalid"})
            check = false
        }

        if( !this.state.selectedContaBancaria && 
            [this.tiposDeRecebimentoService.pixLabel, this.tiposDeRecebimentoService.debitoLabel, this.tiposDeRecebimentoService.creditoLabel]
            .includes(this.state.natureza)
        ){
            this.setState({inputContaBancariaErrorClass: "is-invalid"})
            check = false
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.debitoLabel){
            if( parseInt(this.state.prazo) !== 0 && parseInt(this.state.prazo) !== 1 ){
                check = false
                this.setState({inputPrazoErrorClass: "is-invalid"})
                this.setState({errorPrazoMessage:"Prazo de Débito deve ser 0 ou 1"})
            }
        }
        
        if(this.state.natureza === this.tiposDeRecebimentoService.outrosLabel){
            if(!this.state.selectedContaContabil){
                check = false
                this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            }
        }

        if(this.state.natureza === this.tiposDeRecebimentoService.estornoTarifaLabel){
            if(!this.state.selectedContaBancaria){
                check = false
                this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            }
        }
        

        return check
    }

    search = () => {
        this.setState({loading: true})
        this.tiposDeRecebimentoService.search()
        .then(response => {
            this.setState({tiposDeRecebimento: response.data})
            this.setState({loading: false})
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    searchPdvCaixa = () => {
        this.pdvCaixaService.search()
        .then(response => {
            this.setState({pdvCaixaList: this.lancamentoFechamentoCaixaService.formatArray(response.data) })
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    filterName = () => {
        var filteredList = []
        this.state.tiposDeRecebimento.forEach(element => {
            // if(element.nomeTipoDeRecebimento.includes(this.state.nomeTipoDeRecebimento.toUpperCase()))
            if(
                GeneralServices.includeString(element.nomeTipoDeRecebimento, this.state.nomeTipoDeRecebimento)
                || GeneralServices.includeString(element.numeroDeChamada, this.state.nomeTipoDeRecebimento)
            )
            filteredList.push(element)
        })
        return filteredList
    }

    cadastrarTipoDeRecebimento = () => {
        this.resetView()
        if(this.checkData()){
            this.setState({loading: true})
            this.tiposDeRecebimentoService.cadastrarTipoDeRecebimento(
                {
                    nomeTipoDeRecebimento: this.state.nomeTipoDeRecebimento.trim().toUpperCase(),
                    natureza: this.state.natureza,
                    taxa: this.state.taxa,
                    prazo: this.state.prazo,
                    tipoDeCorte: this.state.tipoDeCorte,
                    diaDaSemana: this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue ? this.state.diaDaSemana.toUpperCase() : "",
                    diasDoMes: this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue ? this.state.diasDoMes: [],
                    nomeContaContabil: this.state.nomeContaContabil ? this.state.nomeContaContabil.trim().toUpperCase() : "",
                    idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
                    contaBancariaId: this.state.selectedContaBancaria.id,
                    pdvCaixaId: this.state.selectedPdvCaixaId,
                    destacadoDRE: this.state.destacadoDRE
                }
            )
            .then(response => {
                popUp.successPopUp("Tipo de recebimento cadastrado com sucesso")
                this.resetForm()
                this.search()
                this.setState({loading: false})
                
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
            })
        }
    }

    deleteMultipleTiposDeRecebimento = (listOfId) => {
        if(listOfId){
            this.setState({loading: true})
            this.setState({disableDeleteButton: true})
            var object = {
                listOfTiposDeRecebimentoId: listOfId,
            }
            this.tiposDeRecebimentoService.deleteMultipleTiposDeRecebimento(object)
            .then( response => {
                popUp.successPopUp("Tipo(s) de Recebimento deletado(s) com sucesso")
                this.setState({loading: false})
                this.search()
            }).catch(error => {
                HandleErrorService.handleError(error)
                this.setState({loading: false})
                this.setState({disableDeleteButton: false})
            })
            
        }
    }

    render() {

        const renderTaxa = () => {
                return (
                    <div className = "col-md-4 " >
                        <FormGroup label = "Taxa (%)" htmlFor = "InputTaxa">
                            <div className="input-group mb-3">
                            <input style={{color: '#000'}} type="number"
                                className={"form-control " + this.state.inputTaxaErrorClass}
                                value = {this.state.taxa}
                                name="taxa"
                                onChange={this.handleTaxaChange}
                                onKeyPress={this.handleKeypress}
                                id="inputTaxa"
                                placeholder="Digite a taxa"
                                disabled={this.state.disableTaxa} 
                                />
                            <span className="input-group-text">%</span>
                            <div className="invalid-feedback">{this.state.errorTaxaMessage}</div>
                            </div>
                        </FormGroup>
                        </div>
                )
        }

        const renderPrazoAndTipoCorteAndNomeContaContabil = () => {
                return (
                    <div className = "row">
                    <div className = "col-md-4">
                    <FormGroup label = "Prazo (dias)" htmlFor = "InputPrazo">
                    <input style={{color: '#000'}} type="number"
                            className={"form-control " + this.state.inputPrazoErrorClass}
                            value = {this.state.prazo}
                            name="prazo"
                            onChange={this.handlePrazoChange}
                            onKeyPress={this.handleKeypress}
                            id="inputprazo"
                            placeholder="Digite o prazo em dias"
                            disabled={this.state.disablePrazo} 
                            />
                    <div className="invalid-feedback">{this.state.errorPrazoMessage}</div>
                    </FormGroup>
                    </div>

                    <div className = "col-md-4">
                    <FormGroup label = "Tipo de Corte " htmlFor = "InputTipoCorte">
                        <SelectMenu  className={"form-control " + this.state.inputTipoCorteErrorClass}
                            name="tipoDeCorte"
                            list={this.tiposDeRecebimentoService.tipoCorteList} 
                            value={this.state.tipoDeCorte}
                            onChange={this.handleTipoDeCorteChange}
                            disabled={this.state.disableTipoDeCorte}
                        /> 
                        <div className="invalid-feedback">{this.state.errorTipoCorteMessage}</div>  
                    </FormGroup> 
                    </div>
                    {renderDiaDaSemana()}

                    {renderDiasDoMes()}

                    {renderNomeContaContabil()}

                </div>
                )
        }
        const renderDiaDaSemana = () => {
            if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDaSemanaValue)
            return (
                    <div className = "col-md-3">
                        <DiaDaSemanaSelect
                            htmlFor="diaDaSemanaCadastro"
                            inputDiaDaSemanaErrorClass = {this.state.inputDiaDaSemanaErrorClass}
                            diaDaSemana = {this.state.diaDaSemana}
                            handleChange = {this.handleChange}
                            errorDiaDaSemanaMessage = {this.state.errorDiaDaSemanaMessage}

                    />
                    </div>
            )
        }

        const renderDiasDoMes = () => {
            if(this.state.tipoDeCorte === this.tiposDeRecebimentoService.diaDoMesValue)
            return(
                <div className = "col-md-3">
                    <DiasDoMesMultiSelect
                        htmlFor="InputDiasDoMes"
                        inputDiasDoMesClass = {this.state.inputDiasDoMesClass}
                        diasDoMes = {this.state.diasDoMes}
                        name="diasDoMes"
                        handleChange = {this.handleChange}
                        errorDiasDoMesMessage = {this.state.errorDiasDoMesMessage}
                    />
                </div>
            )
        }

        const renderNomeContaContabil = () => {
            if(this.state.natureza === this.tiposDeRecebimentoService.dinheiroLabel){
                return (
            <div className = "col-md-4">
                <label htmlFor="date">Nome da Conta Contábil (Ex: Caixa, Fundo Fixo)</label>
                <input style={{color: '#000'}} type="text"
                            className={"form-control " + this.state.inputNomeContaContabilErrorClass}
                            value = {this.state.nomeContaContabil}
                            name="nomeContaContabil"
                            onChange={this.handleChange}
                            id="nomeContaContabil"
                            placeholder="Digite o nome da conta contábil"
                            />
                <div className="invalid-feedback">{this.state.errorNomeContaContabilMessage}</div>
            </div>
                )
            }
        }

        const renderContaBancariaInput = () => {
            if( this.state.natureza  === this.tiposDeRecebimentoService.pixLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.debitoLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.creditoLabel ||
                this.state.natureza  === this.tiposDeRecebimentoService.estornoTarifaLabel
            ){
                return (
                    <InputChooseContaBancaria
                        excludeFundosFixos
                        excludeAplicacaoFinanceira
                        selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                        errorContaBancariaMessage={this.state.errorContaBancariaMessage}
                    />
                )
            }
        }

        const renderInputChooseContaContabil = () => {
            if(this.state.natureza === this.tiposDeRecebimentoService.outrosLabel)
            return (
                <div className="row">
                <div className='col-md-4'>
                <InputChooseContaContabil
                    noHTMLFormat
                    selectedContaContabilLabel={this.state.selectedContaContabilLabel}
                    push={this.props.history.push}
                    handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    errorContaContabilMessage={this.state.errorContaContabilMessage}
                />

                </div>
                </div>
            )
        }

        const renderPDVCaixa = () => {
            if(this.state.natureza  === this.tiposDeRecebimentoService.dinheiroLabel){
                return (
                    <div className = "row">
                    <div className = "col-md-4">
                        <label htmlFor="icon">Ponto de Venda (Caixa)</label>
                        <SelectMenu
                            className={"form-control " + this.state.inputPdvCaixaErrorClass}
                            name="selectedPdvCaixaId"
                            list={this.state.pdvCaixaList} 
                            value={this.state.selectedPdvCaixaId}
                            onChange={this.handleChange}
                            // disabled={this.state.isDoingLancamentos}
                        /> 
                        <div className="invalid-feedback">{this.state.errorPdvCaixaMessage}</div>  

                    </div>
                    </div>
                )
            }
        }

        return(
            <div className="bs-docs-section" >
            <Card title = "Cadastrar Tipos de Recebimento">
                <div className = "col-md-12">
                    <div className = "row">
                    
                    <div className = "col-md-4">
                        <FormGroup label = "Natureza" htmlFor = "InputNatureza">
                            <SelectMenu  className={"form-control " + this.state.inputNaturezaErrorClass}
                            name="natureza"
                            list={this.tiposDeRecebimentoService.naturezaList} 
                            value={this.state.natureza}
                            onChange={this.handleNaturezaChange}/> 
                            <div className="invalid-feedback">{this.state.errorNaturezaMessage}</div>  
                        </FormGroup> 
                    </div>

                    <div className = "col-md-4">
                        <label htmlFor="date">Nome do Tipo de Recebimento</label>
                        <input style={{color: '#000'}} type="text"
                                    className={"form-control " + this.state.inputNomeErrorClass}
                                    value = {this.state.nomeTipoDeRecebimento}
                                    name="nomeTipoDeRecebimento"
                                    onChange={this.handleChange}
                                    id="nomeTipoDeRecebimento"
                                    placeholder="Digite o nome para busca ou cadastro"
                                    disabled={this.state.disableNomeTipoDeRecebimento}
                                    />
                        <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                    </div>

                    {renderTaxa()}

                    </div>

                    {renderPrazoAndTipoCorteAndNomeContaContabil()}


                    {renderPDVCaixa()}

                    {renderContaBancariaInput()}

                    {renderInputChooseContaContabil()}

                    <br />

                    <div className = "col-md-4">
                    <CheckBoxComponent
                        label={ConstantsUtil.destacadoNoDRECheckBoxLabel}
                        checked={this.state.destacadoDRE}
                        handleCheckBoxChange={(checked) => this.setState({destacadoDRE: checked})}
                    />
                    </div>

                    <br />

                    <Button 
                        label="Cadastrar"
                        icon="pi pi-plus"
                        onClick = {this.cadastrarTipoDeRecebimento}
                        // style={ {maxHeight: '35px'} }
                    />

                </div>
                
                <br />
                

                <TiposDeRecebimentoTable 
                    list = { this.filterName() }
                    deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    search={this.search}
                    nomeTipoDeRecebimentoSearch={this.state.nomeTipoDeRecebimento.toUpperCase()}
                    push={this.props.history.push}
                    loading = {this.state.loading}
                    // simpleView={false}
                    disableDeleteButton = {this.state.disableDeleteButton || this.props.disableDeleteButton}
                />

            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

CadastrarTiposDeRecebimento.contextType = AuthContext
export default withRouter( CadastrarTiposDeRecebimento )