
import React from 'react'
import Card from '../../components/card'
import { withRouter } from 'react-router-dom'

import * as popUp from '../../components/toastr'
import { AuthContext } from '../../main/authProvider'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../context/constantsUtil'
import { Button } from 'primereact/button'
import TooltipButton from '../../components/tooltipButton'
import GeneralServices from '../../app/service/generalServices'
import CentralService from '../../app/service/central/centralService'
import HandleErrorService from '../../app/service/handleErrorService'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import TableFilters from '../../components/tableFilters'
import FichaDeEstoqueTable from '../product/fichaDeEstoqueTable'
import AuthService from '../../app/service/authService'

class ConferirLancamentoInventario  extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters()
        this.centralService = new CentralService()
    }

    state = {
        
        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',
        
        habilitaBusca: true,
        loading: false,

        datasLancamentosList: [],

        lancamentoAnalisar: null,
        displayAnalisarLancamentoDialog: false,
        loadingAnalise: false,
        diferencaList: [],

        displayFichaDeEstoqueIndividualDialog: false,
        fichaDeEstoqueOutputListDTO: null,

    }    

    // getDataInicial = () => {
        
    //     let dataInicial = new Date()
    //     dataInicial.setDate(dataInicial.getDate() - 3)
    //     return dataInicial;
        
    // }

    // getDataFinal = () => {
        
    //     let dataInicial = new Date()
    //     return dataInicial;

    // }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        // console.log("handleDateChange initial: ", this.state.initialDate)
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})
        // console.log("handleDateChange initialView: ", this.state.initialDateView)

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        return check
    }

    resetView = () => {

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }    
    
    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            this.search()
        }

    }    

    search = () => {
        this.setState({loading: true})
        this.setState({habilitaBusca: false})
        this.centralService.searchDatasLancamentoInventario(this.state.initialDate, this.state.finalDate)
        .then(response => {
            this.setState({datasLancamentosList: response.data.datasLancamentoInventarioStringList.map(item => {
                let obj = {data: item}
                return obj
            }

            )})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    toggleButton = async () => {
        await this.setState({habilitaBusca: !this.state.habilitaBusca})
    }
    
    openAnalisarLancamentoDialog = (rowData) => {
        this.setState({lancamentoAnalisar: rowData})
        this.setState({displayAnalisarLancamentoDialog: true})
        this.searchDivergenciaInventario(rowData.data)
    }

    hideAnalisarLancamentoDialog = () => {
        this.setState({displayAnalisarLancamentoDialog: false})
        this.setState({lancamentoAnalisar: null})
        this.setState({diferencaList: []})
    }

    openFichaDeEstoqueIndividualDialog = (rowData) => {
        this.searchFichaDeEstoqueIndividual(rowData.product);
    }

    hideFichaDeEstoqueIndividualDialog = () => {
        this.setState({displayFichaDeEstoqueIndividualDialog: false})
    }

    searchFichaDeEstoqueIndividual = product => {

        AuthService.startBlockUi();

        let body = {
            dataInicial: null,
            dataFinal: this.state.lancamentoAnalisar.data,
            product: product,
        }

        this.centralService.getFichaDeEstoqueByDateInterval(body)
        .then(response => {
            // console.log("response: ", response.data)
            this.setState({
                fichaDeEstoqueOutputListDTO: response.data,
                displayFichaDeEstoqueIndividualDialog: true
            })
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            AuthService.stopBlockUi()
        })

    }

    searchDivergenciaInventario = data => {
        this.setState({loadingAnalise: true})
        this.centralService.searchDivergenciaInventario(data)
        .then(response => {
            this.trataDivergenciaResponse(response.data.diferencaList)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingAnalise: false})
        })
    }

    trataDivergenciaResponse = (responseList) => {
        responseList.forEach(item => item.diferencaEmModulo = Math.abs(item.diferenca))
        this.setState({diferencaList: responseList})
    }

    render(){

        const renderAnalisarLancamentoDialog = () => {
            if(this.state.displayAnalisarLancamentoDialog)
                return (
                    <Dialog
                        header={`Lançamento (${this.state.lancamentoAnalisar.data})`}
                        visible={this.state.displayAnalisarLancamentoDialog}
                        modal
                        style={{ width: '95%' }}
                        onHide={this.hideAnalisarLancamentoDialog}
                    >
                        {renderDivergenciaTable()}
                    </Dialog>
                )
        }

        const codigoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCodigo,
            this.state.diferencaList,
            "selectedCodigo",
            (name, value) => this.setState({[name]: value}),
            'product.codigo',
            '100%',
            "Selecione"
        )

        const produtoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedProduto,
            this.state.diferencaList,
            "selectedProduto",
            (name, value) => this.setState({[name]: value}),
            'product.descricao',
            '100%',
            "Selecione"
        )

        const quantidadeLancadaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedQuantidadeLancada,
            this.state.diferencaList,
            "selectedQuantidadeLancada",
            (name, value) => this.setState({[name]: value}),
            'quantidadeLancada',
            '100%',
            "Selecione"
        )

        const quantidadeSistemaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedQuantidadeSistema,
            this.state.diferencaList,
            "selectedQuantidadeSistema",
            (name, value) => this.setState({[name]: value}),
            'quantidadeSistema',
            '100%',
            "Selecione"
        )

        const divergenciaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDivergencia,
            this.state.diferencaList,
            "selectedDivergencia",
            (name, value) => this.setState({[name]: value}),
            'diferenca',
            '100%',
            "Selecione"
        )

        const renderDivergenciaTable = () => {
            return (
                <DataTable
                    ref={this.dt}
                    value={this.state.diferencaList}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há lançamentos."
                    showGridlines
                    dataKey="data"
                    loading={this.state.loadingAnalise}
                >

                    <Column
                        header="Código"
                        field="product.codigo"
                        sortable
                        filter filterElement={codigoFilterElement}
                    />

                    <Column
                        header="Produto"
                        field="product.descricao"
                        sortable
                        filter filterElement={produtoFilterElement}
                    />

                    <Column
                        header="Quantidade Lançada no inventário"
                        field='quantidadeLancada'
                        sortable
                        filter filterElement={quantidadeLancadaFilterElement}
                    />

                    <Column
                        header="Quantidade calculada pelo sistema"
                        field='quantidadeSistema'
                        sortable
                        filter filterElement={quantidadeSistemaFilterElement}
                    />

                    <Column
                        header="Divergência"
                        field='diferencaEmModulo'
                        sortable
                        filter filterElement={divergenciaFilterElement}
                        body={rowData => {
                            const classes = classNames({
                                'superavitValue': rowData.diferenca >= 0,
                                'deficitValue': rowData.diferenca < 0,
                                
                            });                            
                            return (
                                <a
                                    className={classes}
                                    onClick={() => this.openFichaDeEstoqueIndividualDialog(rowData)}
                                >
                                    {Math.abs(rowData.diferenca)}
                                </a>
                            )
                        }}
                    />

                </DataTable>
            )            
        }

        const renderFichaDeEstoqueIndividualDialog = () => {
            return(
                <Dialog
                    header={`Ficha de Estoque`}
                    visible={this.state.displayFichaDeEstoqueIndividualDialog}
                    modal
                    style={{ width: '95%' }}
                    onHide={this.hideFichaDeEstoqueIndividualDialog}
                >
                    {renderFichaDeEstoqueIndividualTable()}
                </Dialog>
            )
        }

        const renderFichaDeEstoqueIndividualTable = () => {
            return (
                <FichaDeEstoqueTable
                    fichaDeEstoqueOutputListDTO={this.state.fichaDeEstoqueOutputListDTO}
                    unificar
                    disabled
                />
            )
        }

        const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                <TooltipButton
                    tooltip="Analisar"
                    style={{marginLeft: '5px'}}
                    icon="pi pi-eye"
                    onClick={() => this.openAnalisarLancamentoDialog(rowData)}
                    // disabled={this.props.disabled}
                />
                </React.Fragment>
            );
        }


        const renderTable = () => {
            if(!this.state.habilitaBusca){
                return (
                    <DataTable
                        value={this.state.datasLancamentosList}
                        className="p-datatable-sm small-font"
                        rowHover
                        emptyMessage="Não há lançamentos."
                        showGridlines
                        dataKey="data"
                        loading={this.state.loading}
                    >

                        <Column
                            header="Data"
                            field="data"
                        />

                        <Column
                            header="Analisar"
                            body={actionBodyTemplate}
                        />

                    </DataTable>
                )
            }
        }

        const renderBody = () => {
            return (
                <>
                <div className = "col-md-12">
                    <div className="row">
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        // defaultValue={ConstantsUtil.calendarMesCompletoFilterLabel}
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                        preSetFilterType={ConstantsUtil.calendarMesCompletoFilterLabel}
                        // preSetDataInicial={this.getDataInicial()}
                        // preSetDataFinal={this.getDataFinal()}
                        enableChangeFilterType
                    />

                    </div>
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button
                        label={"Buscar"}
                        className='small-button'
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="small-button p-button-danger"
                        // disabled
                        onClick = {this.toggleButton}
                        // onClick = {() => this.recebimentoRef.current.exportExcel()}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        const renderTooltipButton = () => {
            switch(window.location.pathname){
                case ConstantsUtil.syncImportacaoNFsVendaPathname:
                    return <></>
                    
                case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                    return (
                        <TooltipButton
                            tooltip={this.montaMensagemInfoIntegracaoAdministradoras()}
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'left'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px'}}
                            className="p-button-rounded p-mr-2"
                        />
                    )
    
                case ConstantsUtil.calcularPDVPathname:
                    return (
                        <TooltipButton
                            tooltip={"Calcula o PDV com base nas notas fiscais de saída do período selecionado."}
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'left'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px'}}
                            className="p-button-rounded p-mr-2"
                        />
                    )
            }  
        }

        return(
            <div className="bs-docs-section " >
                <Card
                    title = {"Conferir Lançamentos de Inventário"}
                    rightComponent={renderTooltipButton()}
                >
                    
                    <div className = "col-md-12">

                    <div className="row">

                        {renderBody()}

                    </div>  

                    <div
                        style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
                    >
                        {renderButtons()}
                    </div>

                    {renderTable()}
                    {renderAnalisarLancamentoDialog()}
                    {renderFichaDeEstoqueIndividualDialog()}
                    
                    </div> {/* col-md-12 */}

                </Card>
                <div className="d-flex "/>
            </div>

        )
    }    


}


ConferirLancamentoInventario.contextType = AuthContext

export default withRouter(ConferirLancamentoInventario)