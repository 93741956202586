import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'

import * as popUp from '../../components/toastr'
import ContaBancariaService from '../../app/service/conciliacaoBancaria/contaBancariaService'
import FormGroup from '../../components/form-group'
import SelectMenu from '../../components/selectMenu'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import InputChooseTipoDeRecebimento from '../../components/tipoDeRecebimento/inputChooseTipoDeRecebimento'
import InputChooseContaContabil from '../../components/planoDeContas/inputChooseContaContabil'
import ConstantsUtil from '../../context/constantsUtil'
import FichaBancariaService from '../../app/service/conciliacaoBancaria/fichaBancariaService'
import FichaBancariaTable from '../../components/conciliacaoBancaria/fichaBancariaTable'
import CustomCalendar from '../../components/calendar/customCalendar'
import TipoDePagamentoService from '../../app/service/contasAPagar/tipoDePagamentoService'
import InputChooseTipoDePagamento from '../../components/contasAPagar/inputChooseTipoDePagamento'
import ImportacaoOFXDialog from '../../components/conciliacaoBancaria/importacaoOFXDialog'


class LancarFechamentoBancario extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.contaBancariaService = new ContaBancariaService();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.fichaBancariaService = new FichaBancariaService();

        this.dateInputRef = React.createRef()
        this.inputValorRef = React.createRef();
        this.historicoRef = React.createRef();
        this.complementoHistoricoRef = React.createRef();
        this.tipoDeLancamentoInputRef = React.createRef();
        this.inputTipoDeRecebimentoRef = React.createRef()
    }

    state = {
        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaErrorClass:'',
        errorContaBancariaMessage:'Selecione uma Conta Bancária',

        selectedContaBancariaContraPartida: '',
        selectedContaBancariaContraPartidaLabel: '',
        inputContaBancariaDestinoErrorClass:'',
        errorContaBancariaDestinoMessage:'Selecione uma Conta Bancária',


        loadingContaBancariaTable: false,
        forceShowContaBancariaDialog: false,

        tipoDeLancamento: '',
        tipoDeLancamentoCompleto: '',
        inputTipoDeLancamentoErrorClass: '',
        errorTipoDeLancamentoMessage: 'Escolha o tipo de lançamento',

        tipoDeRecebimentoList: [],
        filteredTipoDeRecebimentoList: [],
        selectedTipoDeRecebimento: '',
        selectedTipoDeRecebimentoLabel: '',
        inputTipoDeRecebimentoErrorClass: '',
        loadingTiposDeRecebimentoTable: false,
        forceShowTipoDeRecebimentoDialog: false,

        tipoDePagamentoList: [],
        selectedTipoDePagamento: '',
        selectedTipoDePagamentoLabel: '',
        inputTipoDePagamentoErrorClass: '',
        loadingTipoDePagamentoTable: false,
        forceShowTipoDePagamentoDialog: false,
        forceTipoDePagamentoListInDialog: false,

        complementoHistorico: '',
        inputComplementoHistoricoErrorClass: '',
        errorComplementoHistoricoMessage: '',

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass:'',
        errorContaContabilMessage: 'Escolha a conta crédito',

        historicoAvulso: '',
        inputHistoricoAvulsoErrorClass: '',
        errorHistoricoAvulsoMessage: '',

        valor: null,
        inputValorErrorClass: '',
        errorValorMessage: '',

        isSavingLancamentos: false,

        fichaBancaria: [],
        loadingFichaBancariaTable: false,

    }

    componentDidMount(){
        this.searchTiposDeRecebimento()
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }

    handleDateChange = async (event) => {

        const previousValue = JSON.parse(JSON.stringify(this.state.dataLancamento))

        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        
        const change = this.state.dataLancamento !== '' && (this.state.dataLancamento !== previousValue)
        if(change){
            if(this.isAplicacaoFinanceira()){
                this.inputValorRef.current.inputRef.current.focus()
            }
            else{
                var select = document.getElementById('selectTipoLancamento')
                select.focus();
            }

            this.callFichaBancaria()

        }


        // if(value !== null && value !== previousValue){
        //     this.setState({forceShowContaBancariaDialog: true})
        // }
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleTipoDeLancamentoChange = async (event) => {
        // this.handleChange(event)
        const value = event.target.value
        const name = event.target.name
        await this.setState({ tipoDeLancamento: value.split("_")[0] })
        await this.setState({ tipoDeLancamentoCompleto: value })
        
        this.setState({selectedContaBancariaContraPartida: ''})
        this.setState({selectedContaBancariaContraPartidaLabel: ''})
        this.setState({selectedTipoDeRecebimento: ''})
        this.setState({selectedTipoDeRecebimentoLabel: ''})
        this.setState({selectedTipoDePagamento: ''})
        this.setState({selectedTipoDePagamentoLabel: ''})
        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})
        this.setState({historicoAvulso: ''})
        this.setState({errorHistoricoAvulsoMessage: ''})

        this.callFichaBancaria()

    }

    handleSelectionContaBancariaChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        
        await this.setState({selectedContaBancaria})
        await this.setState({selectedContaBancariaLabel})

        await this.setState({selectedTipoDeRecebimento: ''})
        await this.setState({selectedTipoDeRecebimentoLabel: ''})
        await this.setState({selectedContaContabil: ''})
        await this.setState({selectedContaContabilLabel: ''})
        await this.setState({historicoAvulso: ''})

        /*
            Quando mudar conta bancária, deve limpar o tipo de pagamento selecionado caso ele esteja vinculado a uma
            conta bancária específica.
        */
        if(this.state.selectedTipoDePagamento && this.state.selectedTipoDePagamento.idContaBancaria) {
            await this.setState({selectedTipoDePagamento: ''})
            await this.setState({selectedTipoDePagamentoLabel: ''})
        }
        
        this.filterTipoDeRecebimento()
        
        this.doNotForceContaBancariaDialog()

        this.callFichaBancaria()

        if(this.isAplicacaoFinanceira()){
            const event = {target: {name: 'selectTipoLancamento', value: this.lancarFechamentoBancarioService.tipoDeLancamentoList[1].value}}
            this.handleTipoDeLancamentoChange(event)
            const tipoDeRecebimento = this.state.tipoDeRecebimentoList.find(item => item.nomeTipoDeRecebimento === ConstantsUtil.nomeTipoDeRecebimentoRendimentoAplicacaoAutomatica)
            await this.generalServices.sleep(100)
            this.inputTipoDeRecebimentoRef.current.handleSelectionTipoDeRecebimentoChange(tipoDeRecebimento)
        }
        else{
            this.searchTiposDePagamento()
        }
            
        this.dateInputRef.current.inputRef.current.focus()

    }

    isFundoFixo = () => {
        return this.state.selectedContaBancaria.fundoFixo
    }

    isAplicacaoFinanceira = () => {
        return this.state.selectedContaBancaria.aplicacaoFinanceira
    }

    searchTiposDePagamento = () => {
        this.searchTiposDePagamentoByContaBancariaId(this.state.selectedContaBancaria.id)
    }

    handleSelectionContaBancariaDestinoChange = async (selectedContaBancariaContraPartida, selectedContaBancariaContraPartidaLabel) => {
        this.setState({selectedContaBancariaContraPartida})
        this.setState({selectedContaBancariaContraPartidaLabel})
        this.inputValorRef.current.inputRef.current.focus()
    }

    handleSelectionTipoDeRecebimentoChange = async (tipoDeRecebimento, label) => {
        await this.setState({selectedTipoDeRecebimento: tipoDeRecebimento})
        await this.setState({selectedTipoDeRecebimentoLabel: label})


        this.hideTipoDeRecebimentoDialog()

        if(this.state.selectedTipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.naoIdentificadoLabel){
            this.complementoHistoricoRef.current.focus()
        }
        else if(
            this.state.selectedTipoDeRecebimento.nomeTipoDeRecebimento !== this.tiposDeRecebimentoService.avulsoLabel
            && !this.isAplicacaoFinanceira()
        ){
            this.inputValorRef.current.inputRef.current.focus()
        }

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})
        this.setState({historicoAvulso: ''})

    }

    handleSelectionTipoDePagamentoChange = async (tipoDePagamento, label) => {
        await this.setState({selectedTipoDePagamento: tipoDePagamento})
        await this.setState({selectedTipoDePagamentoLabel: label})

        this.hideTipoDePagamentoDialog()

        // this.complementoHistoricoRef.current.focus()
        this.inputValorRef.current.inputRef.current.focus()
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})

        this.historicoRef.current.focus()
    }

    handleValorChange = (event) => {
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyPress = async e => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleValorChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter" && !this.state.isSavingLancamentos) {
            this.callSave();
        }
    }

    searchTiposDeRecebimento = () => {
        this.setState({loadingTiposDeRecebimentoTable: true})
        this.tiposDeRecebimentoService.search(true)
        .then(response => {
            this.setState({tipoDeRecebimentoList: response.data})
            this.setState({filteredTipoDeRecebimentoList: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTiposDeRecebimentoTable: false})
        })
    }

    searchTiposDePagamentoByContaBancariaId = (idContaBancaria) => {
        /*
        Faz Busca por conta Bancária com o objetivo de obter todos os tipos de pagamento que não estão associados a nenhuma conta bancária,
        além de trazer os tipos de pagamento de aplicação financeira e de tarifa que estão associados à conta bancária selecionada.
        */

        this.setState({loadingTipoDePagamentoTable: true})

        this.tipoDePagamentoService.searchByContaBancariaId(idContaBancaria, true)
        .then(response => {
            this.setState({tipoDePagamentoList: response.data})
            this.setState({forceTipoDePagamentoListInDialog: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loadingTipoDePagamentoTable: false})
        })
    }

    filterTipoDeRecebimento = async () => {
        //Mostra ao usuário apenas os tipos de recebimento associados à Conta Bancária selecionada.
        var filteredList = this.tiposDeRecebimentoService.filterByContaBancaria(
            this.state.tipoDeRecebimentoList,
            this.state.selectedContaBancaria
        )

        await this.setState({filteredTipoDeRecebimentoList: JSON.parse(JSON.stringify(filteredList)) })

    }
    
    callFichaBancaria = () => {
        // if(this.state.selectedContaBancaria && this.state.dataLancamento && !this.isAplicacaoFinanceira()){
        if(this.state.selectedContaBancaria && this.state.dataLancamento){
            this.searchFichaBancaria()
        }
    }

    montaObjToSearchFichaBancaria = () => {
        
        let obj = {
            contaBancariaId: this.isAplicacaoFinanceira() ? null : this.state.selectedContaBancaria.id,
            aplicacaoFinanceiraId: this.isAplicacaoFinanceira() ? this.state.selectedContaBancaria.id : null,
            dataInicial: this.state.dataLancamento,
            dataFinal: this.state.dataLancamento,
        }

        obj = GeneralServices.defineFiltroTipoLancamentoFichaBancaria(obj, this.isAplicacaoFinanceira, this.state.tipoDeLancamentoCompleto)

        return obj
    }

    searchFichaBancaria = () => {

        this.setState({loadingFichaBancariaTable: true})
        
        this.fichaBancariaService.getFichaBancariaByDateInterval(this.montaObjToSearchFichaBancaria())
        .then(async response => {
            response.data.fichaBancariaList.reverse()
            await this.setState({fichaBancaria: response.data})
            this.setState({loadingFichaBancariaTable: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loadingTable: false})
            this.setState({isFinishedSearch: true})
            this.setState({isSearchingFichaBancaria: false})

        })
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }

    isTipoDeLancamentoTransferencia = () => {
        return this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioTransferenciaLabel
    }

    isTipoDeLancamentoTransferenciaEntrada = () => {
        return this.isTipoDeLancamentoTransferencia 
        && (this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaEntradaLabel)
    }

    isTipoDeLancamentoTransferenciaSaida = () => {
        return this.isTipoDeLancamentoTransferencia 
        && (this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel)
    }

    save = () => {

        //Já popula para o caso de não ser transferência
        var contaOrigem = this.state.selectedContaBancaria;
        var contaDestino= '';

        if(this.isTipoDeLancamentoTransferenciaEntrada()){
            contaOrigem = this.state.selectedContaBancariaContraPartida
            contaDestino = this.state.selectedContaBancaria
        }
        else if(this.isTipoDeLancamentoTransferenciaSaida()){
            contaOrigem = this.state.selectedContaBancaria
            contaDestino = this.state.selectedContaBancariaContraPartida
        }

        const obj = {
            dataCredito: this.state.dataLancamento,
            idContaBancaria: contaOrigem.id,
            tipoDeLancamentoBancario: this.isAplicacaoFinanceira() ? ConstantsUtil.lancamentoBancarioRecebimentoAplicacaoFinanceiraLabel : this.state.tipoDeLancamento,
            idContaBancariaDestino: contaDestino.id,
            idTipoDeRecebimento: this.state.selectedTipoDeRecebimento.id,
            idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
            historicoAvulso: this.state.historicoAvulso,
            idTipoDePagamento: this.state.selectedTipoDePagamento.id,
            complementoHistorico: this.state.complementoHistorico,
            valor: this.state.valor,
        }
        this.setState({isSavingLancamentos: true})
        this.lancarFechamentoBancarioService.save(obj)
        .then(response => {
            this.resetInputs()
            popUp.successPopUp("Lançamento bancário salvo com sucesso!")
            this.setState({isSavingLancamentos: false})
            if(!this.isAplicacaoFinanceira()){
                this.setState({forceShowTipoDeRecebimentoDialog: true})
                this.setState({forceShowTipoDePagamentoDialog: true})
            }
            this.searchFichaBancaria()

        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingLancamentos: false})
        })
    }

    resetInputs = () => {
        if(!this.isAplicacaoFinanceira()){
            this.setState({selectedTipoDeRecebimento: ''})
            this.setState({selectedTipoDeRecebimentoLabel: ''})
        }

        this.setState({historicoAvulso: ''})

        this.setState({complementoHistorico: ''})

        this.setState({valor: null})
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})

        this.setState({inputContaBancariaErrorClass: ""})
        this.setState({inputTipoDeLancamentoErrorClass: ""})
        this.setState({inputTipoDeRecebimentoErrorClass: ""})

        this.setState({inputTipoDePagamentoErrorClass: ''})

        this.setState({inputHistoricoAvulsoErrorClass: ""})
        this.setState({errorHistoricoAvulsoMessage: ""})

        this.setState({inputValorErrorClass: ""})
        this.setState({errorValorMessage: ""})

        this.setState({inputContaContabilErrorClass: ""})
    }

    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        if(!this.state.selectedContaBancaria){
            this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.tipoDeLancamento){
            this.setState({inputTipoDeLancamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
            
            if(!this.state.selectedTipoDeRecebimento){
                this.setState({inputTipoDeRecebimentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }

        }

        if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel){
            if(!this.state.selectedTipoDePagamento){
                this.setState({inputTipoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check=false
            }
        }

        if(
            this.state.selectedTipoDeRecebimento && (this.state.selectedTipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.avulsoTipoDeRecebimentoLabel)
            || this.state.selectedTipoDePagamento && (this.state.selectedTipoDePagamento.nome === ConstantsUtil.avulsoTipoDePagamentoLabel)
        )
        {

            if(!this.state.selectedContaContabil){
                this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }

            if(!this.state.historicoAvulso){
                this.setState({inputHistoricoAvulsoErrorClass: ConstantsUtil.primereactInputErrorClass})
                this.setState({errorHistoricoAvulsoMessage: ConstantsUtil.historicoErrorMessage})
                check = false
            }

            else if(this.state.historicoAvulso.length > 255){
                this.setState({inputHistoricoAvulsoErrorClass: ConstantsUtil.primereactInputErrorClass})
                this.setState({errorHistoricoAvulsoMessage: "Histórico não pode ter mais de 255 caracteres."})
                popUp.infoPopUp("Histórico não pode ter mais de 255 caracteres.")
                check = false
            }
        }


        if(!this.state.valor){
            this.setState({inputValorErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorValorMessage: "Informe o valor do lançamento"})
            check = false
        }


        return check
    }


    hideTipoDeRecebimentoDialog = () => {
        this.setState({forceShowTipoDeRecebimentoDialog: false})
    }

    hideTipoDePagamentoDialog = () => {
        this.setState({forceShowTipoDePagamentoDialog: false})
    }

    doNotForceContaBancariaDialog = () => {
        this.setState({forceShowContaBancariaDialog: false})
    }

    hideImportacaoOFXDialog = () => {
        this.setState({displayImportacaoOFXDialog: false})
    }

    render() {

        const renderImportarOFXButton = () => {
            return (
            <Button 
                label="Importar Extrato"
                className="p-button-success"
                icon="pi pi-upload"
                onClick = {() => this.setState({displayImportacaoOFXDialog: true})}
                // disabled={!this.state.habilitaBusca}
            />
            )
        }

        const renderImportacaoOFXDialog = () => {
            return (
                <ImportacaoOFXDialog
                    visible={this.state.displayImportacaoOFXDialog}
                    hideDialog={this.hideImportacaoOFXDialog}
                />
            )
        }
        
        const renderTipoDeLancamento = () => {
            if(this.state.selectedContaBancaria)
            return (
                <>
                    <div className="col-md-2">
                        <FormGroup label = "Tipo de Lançamento " htmlFor = "InputTipoLancamento">
                            <SelectMenu 
                            id="selectTipoLancamento"
                            className={"form-control " + this.state.inputTipoDeLancamentoErrorClass}
                            ref={this.tipoDeLancamentoInputRef}
                            name="tipoDeLancamento"
                            list={this.lancarFechamentoBancarioService.tipoDeLancamentoList} 
                            value={this.state.tipoDeLancamentoCompleto}
                            // onChange={this.handleChange}
                            onChange={this.handleTipoDeLancamentoChange}
                            disabled={this.isAplicacaoFinanceira()}
                            /> 
                            <div className="invalid-feedback">{this.state.errorTipoDeLancamentoMessage}</div>  
                        </FormGroup> 
                        </div>
                </>
            )
        }

        const renderContaBancariaContraPartidaInput = () => {
            if(this.isTipoDeLancamentoTransferencia())
            return (
                <>
                <div className="col-md-4">
                   <InputChooseContaBancaria
                        label={GeneralServices.getContaBancariaOrigemOuDestinoLabel(false, this.isTipoDeLancamentoTransferencia, this.isTipoDeLancamentoTransferenciaEntrada)}
                        readOnly
                        autofocus
                        excludeAplicacaoFinanceira
                        excludeInativas
                        // forceShowDialog={this.state.forceShowContaBancariaDialog}
                        // doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                        selectedContaBancariaLabel={this.state.selectedContaBancariaContraPartidaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaDestinoChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaLabelErrorClass}
                        errorContaBancariaMessage={this.state.errorContaBancariaLabelMessage}
                        noHTMLFormat
                    />
                </div>
                </>
                    
            )
        }

        const renderTipoDeRecebimentoInput = () => {
            if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel)
            return (
                <>
                   <InputChooseTipoDeRecebimento
                        ref={this.inputTipoDeRecebimentoRef}
                        tipoDeRecebimentoList={this.state.filteredTipoDeRecebimentoList}
                        selectedTipoDeRecebimentoLabel={this.state.selectedTipoDeRecebimentoLabel}
                        renderDialogOnMount={!this.isAplicacaoFinanceira()}
                        forceShowDialog={this.state.forceShowTipoDeRecebimentoDialog}
                        hideDialog={this.hideTipoDeRecebimentoDialog}
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                        inputTipoDeRecebimentoErrorClass={this.state.inputTipoDeRecebimentoErrorClass}
                        disabled={this.isAplicacaoFinanceira()}
                    />
                </>
                    
            )
        }

        const renderTipoDePagamentoInput = () => {
            if(this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel)
            return (
                <>
                   <InputChooseTipoDePagamento
                        selectedTipoDePagamentoLabel={this.state.selectedTipoDePagamentoLabel}
                        renderDialogOnMount
                        search={this.searchTiposDePagamento}
                        forceShowDialog={this.state.forceShowTipoDePagamentoDialog}
                        hideDialog={this.hideTipoDePagamentoDialog}
                        tipoDePagamentoList={this.state.tipoDePagamentoList}
                        forceUpdate={this.state.forceTipoDePagamentoListInDialog}
                        doneForceUpdate={() => this.setState({forceTipoDePagamentoListInDialog: false})}
                        push={this.props.history.push}
                        handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                        inputTipoDePagamentoErrorClass={this.state.inputTipoDePagamentoErrorClass}
                    />
                </>
                    
            )
        }

        const renderContaContabilInput = () => {
            if(
                (this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel
                && this.state.selectedTipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.avulsoTipoDeRecebimentoLabel)
                ||
                (this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel
                && this.state.selectedTipoDePagamento.nome === ConstantsUtil.avulsoTipoDePagamentoLabel)

            )
            return (
                <>
                   <InputChooseContaContabil
                        selectedContaContabilLabel={this.state.selectedContaContabilLabel}
                        tipoLancamentoBancario={this.state.tipoDeLancamento}
                        push={this.props.history.push}
                        renderDialogOnMount
                        handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                        inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                        errorContaContabilMessage={this.state.errorContaContabilMessage}
                    />
                    <div className="col-md-1">
                    <label htmlFor="icon">Histórico</label>
                    <InputTextarea
                        ref={this.historicoRef}
                        className={this.state.inputHistoricoAvulsoErrorClass}
                        value={this.state.historicoAvulso}
                        onChange={(e) => this.setState({historicoAvulso: e.target.value})}
                        rows={5} cols={35}
                        autoResize
                    />
                    </div>
                </>
                    
            )
        }

        const renderComplementoDeHistorico = () => {
            if(
                (
                    this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel
                    &&
                    this.state.selectedTipoDePagamento.nome !== ConstantsUtil.avulsoTipoDePagamentoLabel
                )
                ||
                (
                    this.state.tipoDeLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel
                    &&
                    this.state.selectedTipoDeRecebimento.nomeTipoDeRecebimento === ConstantsUtil.naoIdentificadoLabel
                )
            ){
                return (
                    <div className="col-md-3">
                    <label htmlFor="icon">Complemento de Histórico (Opcional)</label>
                    <InputTextarea
                        ref={this.complementoHistoricoRef}
                        className={this.state.inputComplementoHistoricoErrorClass}
                        value={this.state.complementoHistorico}
                        onChange={(e) => this.setState({complementoHistorico: e.target.value})}
                        rows={5} cols={35}
                        autoResize
                    />
                    </div>
                )
            }
        }

       const renderFichaBancariaTable = () => {
            // if( (this.state.isSearchingFichaBancaria || this.state.isFinishedSearch) && !this.state.habilitaBusca ){
            if( this.state.selectedContaBancaria && this.state.dataLancamento ){
                return (
                    <FichaBancariaTable
                        fichaBancaria={this.state.fichaBancaria}
                        tipoDeRecebimentoList={this.state.tipoDeRecebimentoList}
                        search={this.searchFichaBancaria}
                        loading={this.state.loadingFichaBancariaTable}
                        push={this.props.history.push}
                    />
                )
            }
        }

        return (
            <div className="bs-docs-section" >

            <Card 
                title = "Lançar Fechamento Bancário"
                rightComponent={renderImportarOFXButton()}
            >
                <div className="col-md-12">
                    
                <div className="row">
                
                <div className="col-md-4">
                <InputChooseContaBancaria
                    label={GeneralServices.getContaBancariaOrigemOuDestinoLabel(true, this.isTipoDeLancamentoTransferencia, this.isTipoDeLancamentoTransferenciaEntrada)}
                    readOnly
                    // autofocus
                    forceShowDialog={this.state.forceShowContaBancariaDialog}
                    doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                    selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                    handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                    inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                    errorContaBancariaMessage={this.state.errorContaBancariaMessage}
                    excludeInativas
                    noHTMLFormat
                />

                </div>

                <CustomCalendar
                    ref={this.dateInputRef}
                    id="dataLancamentoFechamentoCaixa"
                    htmlFor="icon"
                    label="Data do Lançamento"
                    inputDateErrorClass={this.state.inputDateErrorClass}
                    value={this.state.dataLancamentoView}
                    name="dataLancamento"
                    onChange={this.handleDateChange}
                    errorDateMessage={this.state.errorDateMessage}
                />

                </div>

                    <br />

                    <div className="row">
                        {renderTipoDeLancamento()}                        

                        {renderContaBancariaContraPartidaInput()}

                        {renderTipoDeRecebimentoInput()}

                        {renderTipoDePagamentoInput()}

                        {renderComplementoDeHistorico()}

                        {renderContaContabilInput()}

                    </div>

                    <div className="row">
                        <div className="col-md-9">

                        </div>
                        <div className="col-md-3">
                        <small id="historicoAvulsoError" className="p-error">{this.state.errorHistoricoAvulsoMessage}</small>
                        </div>
                    </div>

                    <br />


                    <div className="row">
                        <div className="col-md-2">
                        <label htmlFor="valor">Valor do Lançamento</label>
                            <InputNumber 
                                ref={this.inputValorRef}
                                className={this.state.inputValorErrorClass}
                                name="valor"
                                inputId="valorFechamentoBancario"
                                value={this.state.valor}
                                onValueChange={this.handleValorChange}
                                onKeyDown={this.handleKeyPress}
                                // mode="currency" currency="BRL" locale="pt-BR"
                                mode="decimal" locale="pt-BR" minFractionDigits={2}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <small id="valorError" className="p-error">{this.state.errorValorMessage}</small>
                        </div>
                    </div>

                    <br />
                    
                    <Button 
                        label="Salvar Lançamento"
                        icon="pi pi-save"
                        onClick = {this.callSave}
                        disabled = {this.state.isSavingLancamentos}
                    />

                    <br />

                    <br />

                    {renderFichaBancariaTable()}

                </div>

                {renderImportacaoOFXDialog()}

            </Card>
            <div className="d-flex "/>

        </div>
        )

    }

}

LancarFechamentoBancario.contextType = AuthContext

export default withRouter(LancarFechamentoBancario)
