import React from 'react'
import { Prompt, withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import * as popUp from '../../components/toastr'
import InputChooseContaBancaria from '../../components/conciliacaoBancaria/inputChooseContaBancaria'
import ConstantsUtil from '../../context/constantsUtil'
import AplicacaoFinanceiraService from '../../app/service/conciliacaoBancaria/aplicacaoFinanceiraService'
import AplicacaoFinanceiraTable from '../../components/conciliacaoBancaria/aplicacaoFinanceiraTable'

class AplicacaoFinanceira extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        // this.contaBancariaService = new ContaBancariaService();
        this.aplicacaoFinanceiraService = new AplicacaoFinanceiraService();
        this.inputBanco = React.createRef();
    }
    
    state = {
        loading: false,
        forceUpdate: false,

        aplicacaoFinanceiraList: [],
        filteredList: [],

        nome: '',
        inputNomeErrorClass: '',
        errorNomeMessage: 'Digite o nome',

        selectedContaBancaria: '',
        selectedContaBancariaLabel: '',
        inputContaBancariaErrorClass: '',
        errorContaBancariaLabelMessage: 'Informe a Conta Bancária',

        isSavingNewAplicacaoFinanceira: false,

    }

    componentDidMount(){
        this.searchAplicacaoFinanceira()
        this.filterByName(true)
    }

    searchAplicacaoFinanceira = () => {
        this.setState({loading: true})
        this.setState({isSavingNewAplicacaoFinanceira: true})
        this.aplicacaoFinanceiraService.search()
        .then(async response => {
            this.setState({aplicacaoFinanceiraList: response.data})
            this.setState({loading: false})
            await this.setState({filteredList: JSON.parse(JSON.stringify(response.data))})
            this.setState({forceUpdate: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
            this.setState({isSavingNewAplicacaoFinanceira: false})
            this.setState({forceUpdate: true})
        })
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        await this.filterByName()
        this.setState({forceUpdate: true})
    }
    
    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.callSave()
          }
    }

    handleSelectionContaBancariaChange = async (selectedContaBancaria, selectedContaBancariaLabel) => {
        this.setState({selectedContaBancaria})
        this.setState({selectedContaBancariaLabel})
    }

    doneForceUpdate = () => {
        this.setState({forceUpdate: false})
    }

    filterByName = () => {
        var filteredList = JSON.parse(JSON.stringify(this.state.aplicacaoFinanceiraList))
        if(this.state.nome){
            filteredList = this.state.aplicacaoFinanceiraList.filter(aplicacaoFinanceira => {
                return (
                    GeneralServices.includeString(aplicacaoFinanceira.nome, this.state.nome)
                    )
            })
        }
        this.setState({filteredList})
    }

    resetForm = () => {
        this.setState({nome: ''})

        this.setState({selectedContaBancaria: ''})
        this.setState({selectedContaBancariaLabel: ''})
    }

    resetView = () => {
        this.setState({inputContaBancariaErrorClass: false})

        this.setState({inputNomeErrorClass: ''})

    }

    checkData = () => {
        var check = true

        if(!this.state.nome){
            this.setState({inputNomeErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(!this.state.selectedContaBancaria){
            this.setState({inputContaBancariaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        return check
    }

    callSave = () => {

        this.resetView()

        if(this.checkData() && !this.state.isSavingNewAplicacaoFinanceira){ //para evitar duplo clique salvar 2x
            this.setState({isSavingNewAplicacaoFinanceira: true})
            this.aplicacaoFinanceiraService.save(
                {
                    nome : this.state.nome.toUpperCase().trim(),
                    contaBancaria : this.state.selectedContaBancaria
                }
            )
            .then(response => {
                popUp.successPopUp("Aplicação Financeira cadastrada com sucesso!")
                // this.setState({isSavingNewAplicacaoFinanceira: false})
                this.resetForm()
                this.searchAplicacaoFinanceira()
            })
            .catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSavingNewAplicacaoFinanceira: false})
            })
        }
    }

    updateList = async (updatedList) => {
        // updatedList.forEach((item, index) => {
        //         item.nome = item.nome.toUpperCase().trim()  
        //         updatedList[item] = item
        //     }
        // )
        // await this.setState({aplicacaoFinanceiraList: updatedList.map(item => item.nome.toUpperCase().trim())})
        await this.setState({aplicacaoFinanceiraList: updatedList.map(item => {
            item.nome = item.nome.toUpperCase().trim()  
            return item
        })})
        this.update()
    }

    update = () => {
        var list = this.state.aplicacaoFinanceiraList.filter(appFin => appFin.updated)
        this.aplicacaoFinanceiraService.update(list)
        .then(response => {
            popUp.successPopUp("Aplicações Financeiras editadas com sucesso!")
            this.setState({isSavingNewAplicacaoFinanceira: false})
            this.resetForm()
            this.searchAplicacaoFinanceira()
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({isSavingNewAplicacaoFinanceira: false})
        })
    }

    render() {

        return(
            <div className="bs-docs-section" >
            <Card title = "Aplicações Financeiras">
                <div className = "col-md-12">
                <div className="row">

                <div className = "col-md-4">
                <label htmlFor="date">Nome da Aplicação Financeira</label>
                <input style={{color: '#000'}} type="text"
                    className={"form-control " + this.state.inputNomeErrorClass}
                    value = {this.state.nome}
                    name="nome"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyPress}
                    id="nome"
                    placeholder="Digite o nome para busca ou cadastro"
                    // disabled={this.state.disableNomeTipoDeRecebimento}
                />
                <div className="invalid-feedback">{this.state.errorNomeMessage}</div>
                </div> 

                
                <div className = "col-md-4">
                <InputChooseContaBancaria
                    label="Conta Bancária"
                    readOnly
                    excludeAplicacaoFinanceira
                    // autofocus
                    // forceShowDialog={this.state.forceShowContaBancariaDialog}
                    // doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                    selectedContaBancariaLabel={this.state.selectedContaBancariaLabel}
                    excludeFundosFixos={true}
                    handleSelectionContaBancariaChange={this.handleSelectionContaBancariaChange}
                    inputContaBancariaErrorClass={this.state.inputContaBancariaErrorClass}
                    errorContaBancariaMessage={this.state.errorContaBancariaLabelMessage}
                    noHTMLFormat
                />

                </div>


                </div> 

                <br />

                <Button 
                    label="Cadastrar"
                    icon="pi pi-plus"
                    onClick = {this.callSave}
                />

                </div> {/* fechamendo da div col-md-12 */}

                <br />

                <AplicacaoFinanceiraTable 
                    // list = { this.filterByName() }
                    list = { this.state.filteredList }
                    updateList={this.updateList}
                    searchNome={this.state.nome}
                    search={this.searchAplicacaoFinanceira}
                    // deleteMultiple = {this.deleteMultipleTiposDeRecebimento}
                    push={this.props.history.push}
                    loading = {this.state.loading}
                    allowEdit
                    forceUpdate={this.state.forceUpdate}
                    doneForceUpdate={this.doneForceUpdate}
                    // disableDeleteButton = {this.state.disableDeleteButton || this.props.disableDeleteButton}
                />

            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

AplicacaoFinanceira.contextType = AuthContext

export default withRouter(AplicacaoFinanceira)