import React from 'react'
import Card from '../../../components/card'
import { withRouter } from 'react-router-dom'

import * as popUp from '../../../components/toastr'
import GeneralServices from '../../../app/service/generalServices'
import { AuthContext } from '../../../main/authProvider'
import LancamentoFechamentoCaixaService from '../../../app/service/contasAReceber/lancamentoFechamentoCaixaService'
import { Button } from 'primereact/button'
import HandleErrorService from '../../../app/service/handleErrorService'
import ProgressBarComponent from '../../../components/progress/progressBar'
import CustomCalendar from '../../../components/calendar/customCalendar'
import InputChooseTipoDeRecebimento from '../../../components/tipoDeRecebimento/inputChooseTipoDeRecebimento'
import TiposDeRecebimentoService from '../../../app/service/tiposDeRecebimentoService'
import ConstantsUtil from '../../../context/constantsUtil'

class ReprocessarLancamentoFechamentoDeCaixa extends React.Component {

    constructor(){
        super();
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
    }

    state = {

        dataLancamento: '',
        dataLancamentoView: '',
        inputDateErrorClass:'',
        errorDateMessage:'',

        selectedTipoDeRecebimento: {},
        selectedTipoDeRecebimentoLabel: '',
        inputTipoDeRecebimentoErrorClass: '',

        tipoDeRecebimentoList: [],

        beginReprocess: false,
        reprocessing: false,
        progressKey: '',

        disableButton: false,

        finishedSearch: false,

    }

    componentDidMount(){
        this.searchTiposDeRecebimento()
    }

    handleDateChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        const nameView = name+"View"
        await this.setState({[nameView]: value })
        
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleSelectionTipoDeRecebimentoChange = (tipoDeRecebimento, label) => {
        this.setState({selectedTipoDeRecebimento: tipoDeRecebimento})
        this.setState({selectedTipoDeRecebimentoLabel: label})
    }

    searchTiposDeRecebimento = () => {
        this.tiposDeRecebimentoService.search()
        .then(async response => {
            var filteredTipoDeRecebimentoList = response.data.filter(tipoDeRecebimento => !ConstantsUtil.naturezasRecebimentoNaoFechamentoCaixa.includes(tipoDeRecebimento.natureza))
            await this.setState({tipoDeRecebimentoList: filteredTipoDeRecebimentoList})
            this.setState({finishedSearch: true})
        }).catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    callReprocessLancamentos = () => {
        this.resetView()
        if(this.checkData()){
            this.reprocessLancamentos()
        }
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ""})
        this.setState({errorDateMessage: ""})

        this.setState({inputTipoDeRecebimentoErrorClass: ''})

        this.setState({progressKey: ''})
        this.setState({beginReprocess: false})
        this.setState({reprocessing: false})
    }

    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: "p-invalid"})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        // if(!this.state.selectedTipoDeRecebimento){
        //     this.setState({inputTipoDeRecebimentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
        //     check = false
        // }

        return check
    }

    reprocessLancamentos = () => {

        this.setState({disableButton: true})
        
        this.lancamentoFechamentoCaixaService.reprocess(this.state.dataLancamento, this.state.selectedTipoDeRecebimento)
        .then(async response => {
            await this.setState({progressKey: response.data})
            this.setState({beginReprocess: true})
            this.setState({reprocessing: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({reprocessing: false})
            this.setState({disableButton: false})
        })
    }

    endReprocess = () => {
        this.setState({reprocessing: false})
        this.setState({disableButton: false})
        popUp.successPopUp("Lançamentos reprocessados com sucesso.")
    }


    render() {

        const renderProgressBar = () => {
            if(this.state.beginReprocess){
                return(
                    <>
                    <ProgressBarComponent
                        progressKey={this.state.progressKey}
                        endProgress={this.endReprocess}
                        successNoticeMessage="Reprocessamento concluído com sucesso!"
                        failNoticeMessage="Falha no reprocessamento. Tente novamente."
                    />
                    </>
                )
            }

        }

        const renderInputChooseTipoDeRecebimento = () => {
            if(this.state.finishedSearch){
                return (
                    <div className="col-md-4">
                    <InputChooseTipoDeRecebimento
                        selectedTipoDeRecebimentoLabel={this.state.selectedTipoDeRecebimentoLabel}
                        tipoDeRecebimentoList={this.state.tipoDeRecebimentoList}
                        // tipoDeRecebimentoList={this.filterTipoDeRecebimento()}
                        noHTMLFormat
                        push={this.props.history.push}
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                        inputTipoDeRecebimentoErrorClass={this.state.inputTipoDeRecebimentoErrorClass}
                    />
                    </div>
                )
            }
        }

        return (

            <div className="bs-docs-section" >
                <Card title = "Reprocessar Lançamentos de Caixa">
                    <div className="col-md-12">
                        
                        <div className = "row">
                            
                            <div className="col-md-2">
    
                            {/* <div className="row"> */}
                                
                            {/* <div className="col-md-4"> */}
                                {/* <span className="p-float-label"> */}
                                {/* <label htmlFor="icon">A partir de:</label> */}
                                <CustomCalendar
                                    id="dataLancamentoFechamentoCaixa"
                                    htmlFor="icon"
                                    label="A partir de:"
                                    inputDateErrorClass={this.state.inputDateErrorClass}
                                    value={this.state.dataLancamentoView}
                                    name="dataLancamento"
                                    // style={{minWidth:'490px'}}
                                    onChange={this.handleDateChange}
                                    disabled={this.state.reprocessing}
                                    noHTMLFormat
                                    errorDateMessage={this.state.errorDateMessage}
                                />

                            </div>

                            {renderInputChooseTipoDeRecebimento()}

                                {/* </div> */}

                                {/* <div className="row">
                                    <small id="dateErrorNotice" className="p-error">{this.state.errorDateMessage}</small>
                                </div> */}

                                
                                </div>

                                <br />
                                
                                <div className="row">
                                <div className="col-md-4">
                                <Button 
                                    label="Reprocessar Lançamentos"
                                    icon="pi pi-refresh"
                                    onClick = {this.callReprocessLancamentos}
                                    disabled={this.state.reprocessing || this.state.disableButton}
                                />
                                </div>
                                </div>

                                <br />

                                {renderProgressBar()}

                                </div>
                </Card>
            <div className="d-flex "/>
        </div>    

        )

    }

}

ReprocessarLancamentoFechamentoDeCaixa.contextType = AuthContext

export default withRouter(ReprocessarLancamentoFechamentoDeCaixa)