import WorkerApiService from "../../workerApiServices";

class FichaComparativaService extends WorkerApiService {

    constructor() {
        super('/api/fichaComparativaPrevisaoBanco')
    }

    generate(dataInicial, dataFinal, tipo){
        const body = {
            // ipoDePagamento: selectedTipoDePagamento,
            dataInicial,
            dataFinal,
            tipo
        }
        return this.post(`/generate`, body)
    }

}

export default FichaComparativaService