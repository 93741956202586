import React from 'react'
import AuthService from '../app/service/authService'
import JwtService from '../app/service/loginService'

export const AuthContext = React.createContext()
export const AuthConsumer = AuthContext.Consumer
const AuthProvider = AuthContext.Provider


class AuthenticationProvider extends React.Component {

    constructor(){
        super();
        this.jwtService = new JwtService();
    }

    state = {
        isAuth: AuthService.isAuth(),
        apiContextPath: '',
    }
    
    addProvisionalApiContextPath = (apiContextPath) => {
        AuthService.addProvisionalApiContextPath(apiContextPath)
    }

    beginSession = (user) => {
        AuthService.login(user)
        // this.setState({isAuth: true, userLoggedIn: email})
        this.setState({isAuth: true})
    }

    endSession = () => {
        AuthService.logOut()
        // this.setState({isAuth: false, userLoggedIn: null})
        this.setState({isAuth: false})
    }

    checkSessionExpirationTime = () => {
        if(Date.now() > AuthService.getCheckDate() + 24*3600*1000){
        }
    }

    getUserLoggedIn = () => {
        return AuthService.getUserLoggedIn()
    }

    render() {

        const context = {
            userLoggedIn: this.getUserLoggedIn,
            apiContextPath: this.state.apiContextPath,
            isAuth: this.state.isAuth,
            perfil: AuthService.getPerfil(),
            addProvisionalApiContextPath: this.addProvisionalApiContextPath,
            beginSession: this.beginSession,
            endSession: this.endSession,
            checkSessionExpirationTime: this.checkSessionExpirationTime
        }

        return (
            <AuthProvider value={context}>
                {this.props.children}
            </AuthProvider>
        )
    }
}

export default AuthenticationProvider