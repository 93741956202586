import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'


class DREDestacadoTable extends React.Component {

    state = {
        
        loading: false,
        displayFichaBancariaDialog: false,

        forceUpdateDialog: false,
        elementToView: null,

        expanded: false,

    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();

    }

    getTableHeaders = () => {
        
        const periodo = this.props.DRE ? this.props.DRE.initialDate + " - " + this.props.DRE.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData) => {
                
        // var fichaBancaria = rowData.fichaBancariaUnificada
        // fichaBancaria.initialDate = this.props.DRE ? this.props.DRE.initialDate : ''
        // fichaBancaria.finalDate = this.props.DRE ? this.props.DRE.finalDate : ''


        this.setState({elementToView: rowData})
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateDialog: true})

        this.setState({fichaBancariaRecebimento: rowData.total > 0})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        this.setState({fichaBancariaRecebimento: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel,
            'small-font': true
            // 'small-height': true
        }

        return obj
    }

    formattedDREMensalPagamentoToExportByExcel(){
        var formattedList = []

        this.props.DRE.dreDestacadoList.forEach(item =>{
            var obj = {
                Nome: item.nome,
                Valor: item.total
            }
            // item.dreGrupoContaBancariaList.forEach(contaBancariaColumn => {
            //     const nomeBanco = contaBancariaColumn.contaBancaria.nomeBanco
            //     var obj2 = { [nomeBanco]: contaBancariaColumn.valorTotal}
            //     Object.assign(obj, obj2);
            // })
            // obj.Total = item.total
            formattedList.push(obj)
        })

        return formattedList
    }

    formattedFichaBancariaToExport = (list, workbook) => {
        list.forEach(drePagamento => {
            if(drePagamento.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel){
                this.props.DRE.contaBancariaList.forEach(contaBancaria => {
                    //as funções biblioteca xlsx exigem que o nome do sheet não exceda 31 caracteres.
                    var newSheetName = (drePagamento.grupoDePagamento.nome + " - " + contaBancaria.nomeBanco).slice(0, 31)
                    var fichaBancaria = drePagamento[contaBancaria.id].fichaBancaria.fichaBancariaList
                    if(fichaBancaria.length !== 0){
                        var formattedFichaBancaria = fichaBancaria.map(lancamento => {
                            const {data, descricao, saida, saldo, historico} = lancamento
                            return {data, descricao, saida, saldo, historico}
                        })
                    
                        // console.log("formatted: ", formattedFichaBancaria)
                        // console.log("ficha bancaria: ", fichaBancaria)
                        var newWorksheet = GeneralServices.jsonToSheet(formattedFichaBancaria)
                        var newSheet = {[newSheetName]: newWorksheet}
                        Object.assign(workbook.Sheets, JSON.parse(JSON.stringify(newSheet)));
                        workbook.SheetNames.push(newSheetName)
                    }
                })
            }
        })
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalPagamentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.DRE.initialDate + " - " + this.props.DRE.finalDate
            const worksheet = this.generateWorkSheet()
            const sheetName = periodo
            var workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            // this.formattedFichaBancariaToExport(this.props.DRE.dreDestacadoList, workbook)
            this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Resultados Não Operacionais - ${periodo} - VisãoRJ`);
    }

    filterList = () => {
        if(!this.state.expanded && this.props.DRE){
            let listToBeReturned = this.props.DRE.dreDestacadoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            return listToBeReturned
        }

        return this.props.DRE.dreDestacadoList
    }     

    render (){

        const renderContasBancariasColumn = () => {
            var contasBancariasColumnList = []

            if(this.props.DRE){
                this.props.DRE.contaBancariaList.forEach(contaBancaria => {
                    contasBancariasColumnList.push(renderColumnItem(contaBancaria))
                });
            }


            return contasBancariasColumnList
        }

        const renderColumnItem = (contaBancaria) => {
            return (
                <Column
                    field={contaBancaria.nomeBanco}
                    header={contaBancaria.nomeBanco}
                    style ={ {width: '300px'} }
                    body={rowData => renderColumn(rowData, contaBancaria)}
                    // sortable
                />
            )
        }

        const renderColumn = (rowData, contaBancaria) => {

            const valor = GeneralServices.showFormattedIfNotNull(rowData[contaBancaria.id].valor, true)

            return(
                <>
                <div className='row'>
                <div className='col-md-6'>
                {valor}
                </div>

                {/* {renderViewLancamentosButton(rowData, contaBancaria, valor)} */}
                
                </div>
                </>
            )
        }

        const renderTotalColumn = (rowData) => {
            // const valor = GeneralServices.showFormattedIfNotNull(rowData.total, true)
            // const valor = renderStyledValue(rowData)

            return(
                <>
                {/* <div className='row'>
                <div className='col-md-6'>
                    {renderStyledValue(rowData)}
                </div> */}

                {renderViewLancamentosLink(rowData, rowData.showTotal)}
                
                {/* </div> */}
                </>
            )

        }
        
        const renderViewLancamentosLink = (rowData, valor) => {
            
            const classes = classNames({
                'superavitValue': valor > 0,
                'deficitValue': valor<0
            });
            
            const cond1 = rowData.total !== 0
            const cond2 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){

                return(
                    <a 
                        className={classes}
                        // href='/DRE'
                        onClick={() => this.viewLancamentos(rowData)}
                    >
                        {/* {valor} */}
                        {valor ? GeneralServices.showFormattedIfNotNull(valor, true) : ''}
                    </a>
                )
            }
            else{
                return(
                    <div className={classes}>
                        {GeneralServices.showFormattedIfNotNull(valor, true)}
                    </div>
                )
            }
        }

        // const renderViewLancamentosButton = (rowData, valor) => {
        //     const cond1 = rowData.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
        //     const cond2 = rowData.fichaBancariaUnificada.fichaBancariaList && rowData.fichaBancariaUnificada.fichaBancariaList.length > 0
        //     if(cond1 && cond2){
        //         return(
        //             <div className='col-md-1'>
        //             <Button
        //                 tooltip="Ver Lançamentos" 
        //                 tooltipOptions={{position: 'top'}}
        //                 icon="pi pi-eye"
        //                 className={ "p-button-rounded p-mr-2 " }
        //                 // style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : ''} }
        //                 style={ {marginLeft: '10px', maxHeight: '25px', maxWidth: '25px'} }
        //                 // onClick={() => this.viewContaContabil(this.props.contaContabil)}
        //                 // onClick={() => this.setState({displayFichaBancariaDialog: true})}
        //                 onClick={() => this.viewLancamentos(rowData)}
        //                 />
        //             </div>
        //         )
        //     }
        // }

        const renderStyledValue = (rowData) => {
            const valor = rowData.total
            const formattedValue = GeneralServices.showFormattedIfNotNull(valor, true)
            const isTotais = rowData.nome === ConstantsUtil.tipoTotaisFichaBancariaLabel
            const classes = classNames({
                'superavitValue': valor > 0,
                'deficitValue': valor<0
            });
    
            return (
                <div className={classes}>
                    {formattedValue}
                </div>
            );
        }

        const renderStyledName = (rowData) => {
            // const valor = rowData.total
            // const formattedValue = GeneralServices.showFormattedIfNotNull(valor, true)
            const isTotais = rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
            const classes = classNames({
                'totaisLabel': isTotais && this.state.expanded,
            });
    
            return (
                <div
                    className={classes}
                >
                    {isTotais ? ConstantsUtil.resultadoNaoOperacionalLabel : rowData.showNome}
                </div>
            );
        }

        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'nome',
            '200px',
            "Nome"
        )      
        
        const totalFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedTotal,
            this.filterList(),
            "selectedTotal",
            (name, value) => this.setState({[name]: value}),
            'total',
            '200px',
            "Total"
        )        

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    // className="p-datatable-responsive-demo"
                    // rowClassName={this.rowClass}
                    autoLayout
                    rowHover
                    showGridlines
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    scrollable
                    style={{ maxWidth: '100vw'}}
                    scrollHeight="500px"
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage="Nenhum Resultado encontrado."
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.DRE && this.props.DRE.dreDestacadoList ? this.props.DRE.dreDestacadoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Resultados Destacados"
                >
    
                <Column field="nome" header="Nome"
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    body = {renderStyledName}
                />
    
                {/* {renderContasBancariasColumn()} */}
    
                <Column field="total" header="Valor"
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.total, true)}
                    // body = {rowData => renderStyledValue(rowData)}
                    filter={this.state.expanded} filterElement={totalFilterElement}
                    body = {(rowData) => <div className='right-align'> {renderTotalColumn(rowData)} </div> }
                />
    
                <Column field="porcentagem" header="%"
                    // sortable
                    style ={ {minWidth: ConstantsUtil.widthColumNomeDreConsolidado} }
                    // filter={this.state.expanded} filterElement={porcentagemFilterElement}
                    body = {rowData => <div className='right-align'> {`${rowData.showPorcentagem}%`}</div>}
                />
    
                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return (
                    <Button
                        label="Exportar"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '15px'}}
                    />                    
                )
            }
        }

        return (
            <>

            <div className = "card-header header-small-height">
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            await this.setState({selectedTotal: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />                    
                    <div className='col-md-4'>
                        <h5
                            className='small-font-size'
                        >Resultado Não Operacional</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                {/* <div className='row'>
                <div className='col-md-4'>
                    <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success" onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                    />
                </div>
                </div> */}
                
            </div>
            </div>

            
            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.props.DRE ? this.props.DRE.initialDate : ''}
                finalDate={this.props.DRE ? this.props.DRE.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                search={this.props.search}
                recebimento={this.state.fichaBancariaRecebimento}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />

            </>
        )
    }


}  

export default DREDestacadoTable