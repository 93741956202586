import WorkerApiService from "../../workerApiServices";


class AplicacaoFinanceiraService extends WorkerApiService {
    constructor() {
        super('/api/aplicacaoFinanceira')
    }

    search(){
        return this.get('/search')
    }

    save(aplicacaoFinanceira){
        return this.post('/save', aplicacaoFinanceira)
    }

    update(list){
        return this.put('/update', list)
    }

    implantacaoSaldo(object){
        return this.post("/implantacaoSaldo", object)
    }

    toogleSituacao(aplicacaoFinanceira){
        return this.put('/toggleAtivo', aplicacaoFinanceira)
    }    


}

export default AplicacaoFinanceiraService