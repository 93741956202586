import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'
import * as popUp from '../toastr'
import TableFilters from '../tableFilters'
import BoletoRecebimentoTable from './boletoRecebimentoTable'
import DialogFooter from '../dialogFooter'
import BoletoRecebimentoService from '../../app/service/conciliacaoBancaria/boletoRecebimentoService'

class BoletoRecebimentoDialog extends React.Component {

    constructor(){
        super()
        this.boletoRecebimentoService = new BoletoRecebimentoService()
    }

    state = {
        didUpdated: false,
        list: [],
        loading: false,

    }

    componentDidUpdate(){
        this.init()
    }

    init = () => {
        if(this.props.visible && !this.state.didUpdated){

            console.log("this.props.boletoRecebimentoToView: ", this.props.boletoRecebimentoToView)
            this.setState({loading: true})
            this.boletoRecebimentoService.getByDateAndTipoDeRecebimento(
                this.props.boletoRecebimentoToView.dataLiquidacaoString,
                this.props.boletoRecebimentoToView.tipoDeRecebimento.id
            )
            .then(response => {
                this.setState({list: response.data.movimentoList})
            })
            .catch(error => {
                HandleErrorService.handleError(error)
            })
            .finally(() => {
                this.setState({loading: false})
            })

            this.setState({didUpdated: true})
        }
    }

    hideDialog = () => {
        this.props.hideDialog()
        this.setState({didUpdated: false})
    }

    render(){

        const footer = (
            <DialogFooter hideDialog = {this.hideDialog} disabled/>
        )        

        return (
            <Dialog
                visible={this.props.visible}
                style={{ width: '95vw',}}
                header={"Boletos Emitidos"}
                modal
                baseZIndex={-1}
                className="p-fluid"
                footer={footer}
                onHide={this.hideDialog}            
            >

                <BoletoRecebimentoTable
                    list = {this.state.list}
                    loading = {this.state.loading}
                />


            </Dialog>
        )
    }

    
}

export default BoletoRecebimentoDialog