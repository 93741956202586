import React from 'react'
import { DataTable } from 'primereact/datatable'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import { Column } from 'jspdf-autotable'
import TableFilters from '../../tableFilters'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
// import DialogFooter from '../../dialogFooter'
import ProvisaoTable from '../provisoes/provisaoTable'
import AuthService from '../../../app/service/authService'
import DREFichaBancariaDialog from '../../relatorios/DRE/DREFichaBancariaDialog'
import TooltipButton from '../../tooltipButton'
import AnotacoesDialog from '../../anotacoesDialog'
import AnotacaoService from '../../../app/service/central/anotacaoService'
import * as popUp from '../../toastr'
import HandleErrorService from '../../../app/service/handleErrorService'
import BoletoRecebimentoDialog from '../../conciliacaoBancaria/boletoRecebimentoDialog'
import AnotacoesComponent from '../../anotacoesComponent'


const dataVencimentoBoletoLabel = 'Data de Vencimento do Boleto'
const numeroDocumentoLabel = 'N° Documento'
const tipoDeRecebimentoLabel = 'Tipo de Recebimento'
const valorTotalBoletosLabel = 'Valor Total dos Boletos'
const valorTotalEntradasBancariasLabel = 'Valor Total das Entradas Bancárias'
const saldoLabel = 'Saldo'
const situacaoLabel = 'Situação'

const dataPagamentoParcelaLabel = 'Data de Pagamento da Parcela'
const tipoDePagamentoLabel = 'Tipo de Pagamento'
const valorTotalProvisoesLabel = 'Valor Total das Provisões'
const valorTotalSaidasBancariasLabel = 'Valor Total das Saídas Bancárias'
class FichaComparativaTable extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters()
        this.generalServices = new GeneralServices()
        this.anotacaoService = new AnotacaoService()
    }

    state = {
        valorTotalPrevisto: 0,
        valorTotalSaidasBancarias: 0,
        valorTotalSaldo: 0,
        valoresTotaisJaCalculados: false,
        listaFiltrada: [],


        displayProvisaoDialog: false,
        searchProvisaoResult: null,
        loadingProvisaoTable: false,

        displayFichaBancariaDialog: false,
        forceUpdateFichaBancaraiDialog: false,
        elementToViewSaidaBancaria: null,

        fichaDePagamentoItemAdicionarAnotacao: '',
        
        // displayAnotacoesViewDialog: false,
        isCreateAnotacaoMode: false,
        fichaDePagamentoItemToViewAnotacoes: {},

        displayBoletosRecebimentoDialog: false,
        boletoRecebimentoToView: null,


    }


    getTableHeaders = () => {
        //Para não mostrar "undefined" no header enquanto o backend está processando a requisição
        // const tipoDePagamento = this.props.fichaComparativa.tipoDePagamento && this.props.fichaComparativa.tipoDePagamento.nome ? this.props.fichaComparativa.tipoDePagamento.nome : ""
        // const tipoDePagamentoLabel = "Tipo de Pagamento: " + tipoDePagamento
        
        // const dateFilterType = this.props.fichaComparativa.dateFilterType ? this.props.fichaComparativa.dateFilterType : ''
        // const dateFilterTypeLabel = "Filtrado por: " + dateFilterType
        // const dateFilterTypeLabel = this.isPagamento() ? "Filtrado por: Data de Pagamento" 
        const dateFilterTypeLabel = `Filtrado por: Data de ${this.isPagamento() ? "Pagamento" : "Vencimento"}`

        const periodo = this.props.fichaComparativa.initialDate ? this.props.fichaComparativa.initialDate + " - " + this.props.fichaComparativa.finalDate : ""
        const periodoLabel = "Período: " + periodo

        // return {tipoDePagamento: tipoDePagamentoLabel, dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
        return {dateFilterType: dateFilterTypeLabel, periodo: periodoLabel}
    }

    getLancamentos = () => {
        if(this.props.fichaComparativa && this.props.fichaComparativa.fichaComparativaPrevisaoBancoList){
            let list = this.props.fichaComparativa.fichaComparativaPrevisaoBancoList
            if(!this.state.valoresTotaisJaCalculados){
                this.calculaValorTotal(list)
            }
            return list
        }
        return []
    }

    getListaFiltrada = () => {
        if(this.state.listaFiltrada.length === 0){
            return this.getLancamentos()
        }
        return this.state.listaFiltrada
    }

    calculaValorTotal = async list => {
        let valorTotalPrevisto = 0
        let valorTotalSaidasBancarias = 0
        let valorTotalSaldo = 0
        let listaFiltrada = []

        list.forEach(item => {

            listaFiltrada.push(item)

            if(item.valorPrevisto){
                valorTotalPrevisto += item.valorPrevisto
            }
            
            if(item.valorSaidaBancaria){
                valorTotalSaidasBancarias += item.valorSaidaBancaria
            }
            
            if(item.saldo){
                valorTotalSaldo += item.saldo
            }

        })

        await this.setState({valoresTotaisJaCalculados: true})
        this.setState({ valorTotalPrevisto, valorTotalSaidasBancarias, valorTotalSaldo, listaFiltrada })
    }

    doFilterTipoDePagamento = async (rowData) => {
        if(!this.state.selectedTipoDeRecebimentoOuPagamento){
            /*
            selectedTipoDeRecebimentoOuPagamento NÃO setado, ou seja, usuáro clicou para filtrar pelo Tipo de Pagamento.
            */
            let valueList = [this.isPagamento() ? rowData.tipoDePagamento.nome : rowData.tipoDeRecebimento.nomeTipoDeRecebimento]
            this.setState({selectedTipoDeRecebimentoOuPagamento: valueList})
            this.dt.current.filter(valueList, this.isPagamento() ? ConstantsUtil.tipoDePagamentoNomeProperty: ConstantsUtil.tipoDeRecebimentoNomeProperty, 'in');
        }
        else{
            /*
            selectedTipoDeRecebimentoOuPagamento setado, ou seja, usuáro clicou para desfazer o filtro pelo Tipo de Pagamento.
            */
            await this.setState({selectedTipoDeRecebimentoOuPagamento: null})
            this.dt.current.filter(this.state.selectedTipoDeRecebimentoOuPagamento, this.isPagamento() ? ConstantsUtil.tipoDePagamentoNomeProperty: ConstantsUtil.tipoDeRecebimentoNomeProperty, 'in');
        }
    }

    detalhaPrevisao = (rowData) => {
        if(this.isPagamento()){
            this.openProvisoesDialog(rowData)
        }
        else{
            this.openBoletosRecebimentoDialog(rowData)
        }
    }

    openBoletosRecebimentoDialog = async (rowData) => {
        // console.log("rowData: ", rowData)
        await this.setState({
            displayBoletosRecebimentoDialog: true,
            boletoRecebimentoToView: rowData
        })
    }

    openProvisoesDialog = async rowData => {
        await this.setState({displayProvisaoDialog: true})
        this.setState({loadingProvisaoTable: true})
        
        // console.log("openProvisoesDialog: ", rowData)

        const obj = {
            filterTypeDate: ConstantsUtil.dataPagamentoValue,
            initialDate: rowData.dataLiquidacaoString,
            finalDate: rowData.dataLiquidacaoString,
            tipoDePagamento: rowData.tipoDePagamento
        }

        let onSuccess = async (responseData) => {
            this.setState({searchProvisaoResult: responseData.dados})
            this.setState({valorTotal: responseData.valorTotal})
        }

        let onFinally = () => {
            this.setState({loadingProvisaoTable: false})
        }

        this.generalServices.searchProvisoes(
            obj,
            onSuccess,
            onFinally
        )
    }

    hideProvisoesDialog = () => {
        this.setState({displayProvisaoDialog: false})
    }

    openFichaBancariaDialog = async rowData => {
        
        if(!rowData.valorSaidaBancaria){
            return;
        }

        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateFichaBancaraiDialog: true})
        await this.setState({elementToViewSaidaBancaria: {
            initialDate: rowData.dataLiquidacaoString,
            finalDate: rowData.dataLiquidacaoString,
            tipo: this.isPagamento() ? ConstantsUtil.tipoDePagamentoFiltroLabel : ConstantsUtil.tipoDeRecebimentoFiltroLabel,
            tipoDePagamentoDTO: rowData.tipoDePagamento ? rowData.tipoDePagamento : null,
            nome: this.isPagamento() ? rowData.tipoDePagamento.nome : rowData.tipoDeRecebimento.nomeTipoDeRecebimento,
            id:  this.isPagamento() ? rowData.tipoDePagamento.id : rowData.tipoDeRecebimento.id,
            idLancamentoBancario: this.isPagamento() ? null : rowData.idLancamentoFechamentoBancario
        }})
        // console.log("openFichaBancariaDialog this.state.elementToViewSaidaBancaria: ", this.state.elementToViewSaidaBancaria)
    }

    hideFichaBancariaDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        this.setState({elementToViewSaidaBancaria: null})
    }

    research = () => {
        if(this.state.displayProvisaoDialog){
            this.hideProvisoesDialog()
        }
        else if(this.state.displayFichaBancariaDialog){
            this.hideFichaBancariaDialog()
        }
        this.props.search()
    }

    montaRecursoAnotacao = (fichaDePagamentoItem) => {
        // console.log("fichaDePagamentoItem: ", fichaDePagamentoItem)
        let recurso = {
            nome: fichaDePagamentoItem.tipoDePagamento ? fichaDePagamentoItem.tipoDePagamento.nome : '',
            data: fichaDePagamentoItem.dataLiquidacaoString,
            tipo: ConstantsUtil.fichaDePagamentoRecurso,
        }

        // console.log("recurso: ", recurso)

        return recurso

    }

    // openAdicionarAnotacaoDialog = (fichaDePagamentoItem) => {
    //     this.setState({fichaDePagamentoItemAdicionarAnotacao: fichaDePagamentoItem})
    //     // this.setState({displayAnotacoesViewDialog: true})
    //     this.setState({isCreateAnotacaoMode: true})
    // }

    hideAdicionarAnotacaoDialog = () => {
        this.setState({fichaDePagamentoItemAdicionarAnotacao: ''})
        if(this.state.isCreateAnotacaoMode){
            // this.setState({displayAnotacoesViewDialog: false})
            this.setState({isCreateAnotacaoMode: false})
        }
    }

    // openAnotacaoViewDialog = (fichaDePagamentoItem) => {
    //     // this.setState({displayAnotacoesViewDialog: true})
    //     this.setState({fichaDePagamentoItemToViewAnotacoes: fichaDePagamentoItem})
    // }

    hideAnotacoesViewDialog = () => {
        // this.setState({displayAnotacoesViewDialog: false})
        this.setState({fichaDePagamentoItemToViewAnotacoes: {}})
    }

    isPagamento = () => {
        return this.props.tipo === ConstantsUtil.tipoFichaComparativaPagamentoProvisao
    }

    getListToExport = () => {
        if(this.isPagamento()){
            return this.getListToExportPagamento()
        }
        return this.getListToExportRecebimentoBoleto()
    }

    getListToExportPagamento = () => {
        
        // console.log(this.props.fichaComparativa.fichaComparativaPrevisaoBancoList)
        
        let exportList = this.getListaFiltrada().map(item => {
            return {
                [dataPagamentoParcelaLabel]: item.dataLiquidacaoString,
                [tipoDePagamentoLabel]: item.tipoDePagamento.nome,
                [valorTotalProvisoesLabel]: item.valorPrevisto,
                [valorTotalSaidasBancariasLabel]: item.valorSaidaBancaria,
                [saldoLabel]: item.saldo,
            }
        })

        exportList.push(
            {
                [dataPagamentoParcelaLabel]: '',
                [tipoDePagamentoLabel]: ConstantsUtil.totaisTableUpperCaseLabel,
                [valorTotalProvisoesLabel]: this.state.valorTotalPrevisto,
                [valorTotalSaidasBancariasLabel]: this.state.valorTotalSaidasBancarias,
                [saldoLabel]: this.state.valorTotalSaldo,
            }            
        )
        
        return exportList        
    }

    getListToExportRecebimentoBoleto = () => {

        let exportList = this.getListaFiltrada().map(item => {
            return {
                [dataVencimentoBoletoLabel]: item.dataLiquidacaoString,
                [numeroDocumentoLabel]: item.numeroDocumento,
                [tipoDeRecebimentoLabel]: item.tipoDeRecebimento.nomeTipoDeRecebimento,
                [valorTotalBoletosLabel]: item.valorPrevisto,
                [valorTotalEntradasBancariasLabel]: item.valorSaidaBancaria,
                [saldoLabel]: item.saldo,
                [situacaoLabel]: item.situacao
            }
        })

        exportList.push(
            {
                [dataVencimentoBoletoLabel]: '',
                [numeroDocumentoLabel]: '',
                [tipoDeRecebimentoLabel]: ConstantsUtil.totaisTableUpperCaseLabel,
                [valorTotalBoletosLabel]: this.state.valorTotalPrevisto,
                [valorTotalEntradasBancariasLabel]: this.state.valorTotalSaidasBancarias,
                [saldoLabel]: this.state.valorTotalSaldo,
                [situacaoLabel]: ''
            }            
        )
        
        return exportList
    }

    getFilenameToExport = () => {
        return `Ficha de ${this.isPagamento() ? 'Pagamento' : 'Recebimento de Boletos' }_de_${this.props.fichaComparativa.initialDate}_a_${this.props.fichaComparativa.finalDate}`
    }     
    
    exportXLSX = () => {
        // console.log("this.props.fichaComparativa.fichaComparativaPrevisaoBancoList: ", this.props.fichaComparativa.fichaComparativaPrevisaoBancoList)
        let exportList = this.getListToExport()
        GeneralServices.exportExcel(exportList, "Vendas", this.getFilenameToExport())
    }

    getColumnsPDF = () => {
        if(this.isPagamento()){
            return [
                {field: [dataPagamentoParcelaLabel], header: dataPagamentoParcelaLabel},
                {field: [tipoDePagamentoLabel], header: tipoDePagamentoLabel},
                {field: [valorTotalProvisoesLabel], header: valorTotalProvisoesLabel},
                {field: [valorTotalSaidasBancariasLabel], header: valorTotalSaidasBancariasLabel},
                {field: [saldoLabel], header: saldoLabel},
            ]
        }
        return [
            {field: [dataVencimentoBoletoLabel], header: dataVencimentoBoletoLabel},
            {field: [numeroDocumentoLabel], header: numeroDocumentoLabel},
            {field: [tipoDeRecebimentoLabel], header: tipoDeRecebimentoLabel},
            {field: [valorTotalBoletosLabel], header: valorTotalBoletosLabel},
            {field: [valorTotalEntradasBancariasLabel], header: valorTotalEntradasBancariasLabel},
            {field: [saldoLabel], header: saldoLabel},
            {field: [situacaoLabel], header: situacaoLabel},
        ]
    }

    exportPDF = () => {
        let columns = this.getColumnsPDF()
        let exportColumns = columns.map(col => ({ title: col.header, dataKey: col.field }))

        let exportList = this.getListToExport()
        GeneralServices.exportPdf(this.getFilenameToExport(), exportColumns, exportList)
    }

    render(){

        const renderBoletosRecebimentoDialog = () => {
            return (
                <BoletoRecebimentoDialog
                    visible = {this.state.displayBoletosRecebimentoDialog}
                    hideDialog = {() => this.setState({displayBoletosRecebimentoDialog: false})}
                    boletoRecebimentoToView = {this.state.boletoRecebimentoToView}
                />
            )
        }

        const renderProvisaoDialog = () => {
            return (
                <>
                    <Dialog
                        header="Provisões de Pagamento"
                        visible={this.state.displayProvisaoDialog}
                        modal
                        onHide={this.hideProvisoesDialog}
                        // footer={dialogFooter}
                    >
                        <ProvisaoTable
                            searchProvisaoResult={this.state.searchProvisaoResult}
                            loading={this.state.loadingProvisaoTable}
                            perfil = {AuthService.getPerfil()}
                            search={this.research}
                            // disabled
                            // valorTotal={this.state.valorTotal}
                        />
                    </Dialog>
                </>
            )
        }

        const renderDREFichaBancariaDialog = () => {
            // console.log("renderDREFichaBancariaDialog: ", this.state.elementToViewSaidaBancaria)
            return (
                <>
                    <DREFichaBancariaDialog
                        visible={this.state.displayFichaBancariaDialog}
                        initialDate={this.state.elementToViewSaidaBancaria ? this.state.elementToViewSaidaBancaria.initialDate : ''}
                        finalDate={this.state.elementToViewSaidaBancaria ? this.state.elementToViewSaidaBancaria.finalDate : ''}
                        element={this.state.elementToViewSaidaBancaria}
                        recebimento={!this.isPagamento()}
                        boletoRecebimento
                        // unificarEmpresas={this.props.unificarEmpresas}
                        // selectedEmpresas={this.props.selectedEmpresas}
                        forceUpdate={this.state.forceUpdateFichaBancaraiDialog}
                        doneForceUpdate={() => this.setState({forceUpdateFichaBancaraiDialog: false})}
                        search={this.research}
                        // saveAsExcelFile={this.props.saveAsExcelFile}
                        // loadingTable={false}
                        hideDialog={this.hideFichaBancariaDialog}
                        // disabled
                    />                
                </>
            )
        }

        //dataLancamento Filter
        const dataLancamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataLancamento,
            this.getLancamentos(),
            "selectedDataLancamento",
            (name, value) => this.setState({[name]: value}),
            'dataLancamentoString',
            '100%',
            "Selecione"
        )  

        //dataPagamento Filter
        const dataLiquidacaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDataLiquidacao,
            this.getLancamentos(),
            "selectedDataLiquidacao",
            (name, value) => this.setState({[name]: value}),
            'dataLiquidacaoString',
            '100%',
            "Selecione"
        )  

        const numeroDocumentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNumeroDocumento,
            this.getLancamentos(),
            "selectedNumeroDocumento",
            (name, value) => this.setState({[name]: value}),
            "numeroDocumento",
            '100%',
            "Selecione"
        )

        const situacaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSituacao,
            this.getLancamentos(),
            "selectedSituacao",
            (name, value) => this.setState({[name]: value}),
            "situacao",
            '100%',
            "Selecione"
        )

        //tipoDePagamento Filter
        const tipoDeRecebimentoOuPagamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoDeRecebimentoOuPagamento,
            this.getLancamentos(),
            "selectedTipoDeRecebimentoOuPagamento",
            (name, value) => this.setState({[name]: value}),
            this.isPagamento() ? "tipoDePagamento.nome" : "tipoDeRecebimento.nomeTipoDeRecebimento",
            '100%',
            "Selecione"
        )  

        //valorProvisao Filter
        const valorPrevistoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorPrevisto,
            this.getLancamentos(),
            "selectedValorPrevisto",
            (name, value) => this.setState({[name]: value}),
            'valorPrevisto',
            '100%',
            "Selecione"
        )  

        //valorSaidaBancaria Filter
        const valorSaidaBancariaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorSaidaBancaria,
            this.getLancamentos(),
            "selectedValorSaidaBancaria",
            (name, value) => this.setState({[name]: value}),
            'valorSaidaBancaria',
            '100%',
            "Selecione"
        )  

        //saldo Filter
        const saldoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorSaldo,
            this.getLancamentos(),
            "selectedValorSaldo",
            (name, value) => this.setState({[name]: value}),
            'saldo',
            '100%',
            "Selecione"
        )

        const actionBody = (fichaDePagamentoItem) => {
            // console.log("tipoDePagamento: ", tipoDePagamento)
            return (
                <React.Fragment>
                    {/* <TooltipButton
                        tooltip="Ver Anotações"
                        icon="pi pi-eye"
                        onClick={() => this.openAnotacaoViewDialog(fichaDePagamentoItem)}
                        disabled={!fichaDePagamentoItem.anotacoes || fichaDePagamentoItem.anotacoes.length == 0}
                    />
                    <TooltipButton
                        tooltip="Adicionar Anotação"
                        style={{marginLeft: '5px'}}
                        icon="pi pi-plus"
                        onClick={() => this.openAdicionarAnotacaoDialog(fichaDePagamentoItem)}
                        disabled={!this.isPagamento()}
                    /> */}
                    <AnotacoesComponent
                        recurso={this.montaRecursoAnotacao(fichaDePagamentoItem)}
                        anotacoes={fichaDePagamentoItem.anotacoes}
                        disabled={!this.isPagamento()}
                        research={this.research}
                    />
                </React.Fragment>
            )
        }

        const renderNumeroDocumentoColumn = () => {
            if(!this.isPagamento()){
                return (
                    <Column
                        header={`N° Documento`}
                        field={"numeroDocumento"}
                        sortable sortField={"numeroDocumento"}
                        style ={ {width: '80px'} }
                        filter filterElement={numeroDocumentoFilterElement}                
                    />
                )
            }
        }

        const renderSituacaoColumn = () => {
            if(!this.isPagamento()){
                return (
                    <Column
                        header={situacaoLabel}
                        field={"situacao"}
                        sortable sortField={"situacao"}
                        style ={ {width: '80px'} }
                        filter filterElement={situacaoFilterElement}
                    />
                )
            }
        }

        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().dateFilterType}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                    <div className='col-md-1'>
                    </div>
                    <div className='col-md-3'>
                        <Button
                            label="Exportar"
                            icon="pi pi-file-excel"
                            className="p-button-success small-font small-button"
                            onClick={this.exportXLSX}
                            tooltip={ConstantsUtil.exportXlsxLabel}
                            tooltipOptions={{position: 'top'}}
                        />
                        <Button
                            label="Exportar"
                            icon="pi pi-file-pdf"
                            className="p-button-danger small-font small-button"
                            onClick={this.exportPDF}
                            style={{marginLeft: '10px'}}
                            tooltip={ConstantsUtil.exportPdfLabel}
                            tooltipOptions={{position: 'top'}}
                        />
                    </div>
                </div>
                
            </div>
            </div>

            <div className="">
            <DataTable
                ref={this.dt}
                className="p-datatable-sm small-font hidden-tfoot"
                value={this.getLancamentos()}
                onValueChange={this.calculaValorTotal}
                // loading={this.props.loading || this.state.loading}
                loading={this.props.loading}
                // rowClassName={this.rowClass}
                rowHover
                scrollable
                scrollHeight={ this.props.insideDialog ? "25rem" : "32rem"}
                emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                dataKey="id"
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getLancamentos() ? this.getLancamentos().length : '')}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

                {/* <Column
                    header="Data de Lançamento da Provisão"
                    field="dataLancamentoString"
                    sortable sortField='dataLancamento'
                    style ={ {width: '80px'} }
                    filter filterElement={dataLancamentoFilterElement}
                /> */}

                <Column
                    header={this.isPagamento() ? dataPagamentoParcelaLabel : dataVencimentoBoletoLabel}
                    field="dataLiquidacaoString"
                    sortable sortField='dataLiquidacao'
                    style ={ {width: '80px'} }
                    filter filterElement={dataLiquidacaoFilterElement}
                />
                
                {renderNumeroDocumentoColumn()}                

                <Column
                    header={`Tipo de ${this.isPagamento() ? "Pagamento" : "Recbimento"}`}
                    field={this.isPagamento() ? "tipoDePagamento.nome" : "tipoDeRecebimento.nomeTipoDeRecebimento"}
                    sortable sortField={this.isPagamento() ? "tipoDePagamento.nome" : "tipoDeRecebimento.nomeTipoDeRecebimento"}
                    style ={ {width: '200px'} }
                    filter filterElement={tipoDeRecebimentoOuPagamentoFilterElement}
                    // body={ (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.tipoDePagamento.nome, rowIndex) }
                    body={ (rowData, rowIndex) => 
                        GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            this.isPagamento() ? rowData.tipoDePagamento.nome : rowData.tipoDeRecebimento.nomeTipoDeRecebimento,
                            true,
                            false,
                            () => this.doFilterTipoDePagamento(rowData)
                        )
                    }
                />

                <Column
                    header={
                        <div>
                            {this.isPagamento() ? valorTotalProvisoesLabel : valorTotalBoletosLabel}
                            <br />
                            <Button label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalPrevisto, true)}/>
                        </div>
                    }
                    field="valorPrevisto"
                    // sortable
                    style ={ {width: '100px'} }
                    filter filterElement={valorPrevistoFilterElement}
                    body = { (rowData, rowIndex) => {
                        return GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            GeneralServices.showFormattedIfNotNull(rowData.valorPrevisto, true),
                            true,
                            false,
                            () => this.detalhaPrevisao(rowData)
                        )
                        
                    } }
                />

                <Column
                    header={
                        <>
                            {`Valor Total das ${this.isPagamento() ? "Saídas" : "Entradas"} Bancárias`}
                            <br />
                            <Button label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalSaidasBancarias, true)}/>
                        </>
                    }
                    field="valorSaidaBancaria"
                    // sortable
                    style ={ {width: '120px'} }
                    filter filterElement={valorSaidaBancariaFilterElement}
                    // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valorSaidaBancaria, true) }
                    body = { (rowData, rowIndex) => {
                        return GeneralServices.renderTableValueAsLink(
                            rowData,
                            rowIndex,
                            GeneralServices.showFormattedIfNotNull(rowData.valorSaidaBancaria, true),
                            true,
                            false,
                            () => this.openFichaBancariaDialog(rowData)
                            
                        )
                        
                    } }                    
                />

                <Column
                    header={
                        <>
                            {saldoLabel}
                            <br />
                            <Button
                                label={GeneralServices.showFormattedIfNotNull(this.state.valorTotalSaldo, true)}
                                className={!this.state.valorTotalSaldo || this.state.valorTotalSaldo === 0 ? 'p-button-success' : 'p-button-danger'}
                            />
                        </>
                    }
                    field="saldo"
                    // sortable
                    style ={ {width: '100px'} }
                    filter filterElement={saldoFilterElement}
                    body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.saldo, true) }
                />

                {renderSituacaoColumn()}

                <Column
                    header="Ações"
                    style ={ {width: '60px'} }
                    body={actionBody}
                />

            </DataTable>

            </div>

                {renderBoletosRecebimentoDialog()}

                {renderProvisaoDialog()}

                {renderDREFichaBancariaDialog()}

                {/* <AnotacoesDialog
                    visible={this.state.displayAnotacoesViewDialog}
                    hideAnotacoesViewDialog = {this.hideAnotacoesViewDialog}
                    hideAdicionarAnotacaoDialog = {this.hideAdicionarAnotacaoDialog}
                    fichaDePagamentoItemToViewAnotacoes={this.state.fichaDePagamentoItemToViewAnotacoes}
                    research={this.research}
                    creationMode={this.state.isCreateAnotacaoMode}
                    fichaDePagamentoItemAdicionarAnotacao={this.state.fichaDePagamentoItemAdicionarAnotacao}
                /> */}

            </>

        )

    }

}

export default FichaComparativaTable