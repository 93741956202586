import CentralApiService from "../../centralApiServices";


class AnotacaoService extends CentralApiService {

    constructor() {
        super('/api/anotacao')
    }

    search(recurso){
        const body = {
            dataNoRecurso: recurso.data,
            nomeNoRecurso: recurso.nome,
            tipoRecurso: recurso.tipo
        }
        return this.post("/search", body)
    }

    create(anotacao){
        return this.post("/create", anotacao)
    }

    edit(anotacao){
        return this.put("/edit", anotacao)
    }

    deleteAnotacao(anotacao){
        return this.delete(`/delete/${anotacao.id}`)
    }

    deleteAllAnotacao(anotacoesList){
        return this.post(`/deleteAll`, anotacoesList)
    }

}

export default AnotacaoService