import React from 'react'
import { withRouter } from 'react-router-dom'

import {InputSwitch} from 'primereact/inputswitch';
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import { Button } from 'primereact/button'
import Card from '../../components/card'
import * as popUp from '../../components/toastr'
import RelatorioFechamentoCaixaService from '../../app/service/relatorios/relatorioFechamentoCaixaService'
import DateIntervalCalendar from '../../components/calendar/dateIntervalCalendar'
import RelatorioFechamentoCaixaTable from '../../components/relatorios/fechamentoCaixa/relatorioFechamentoCaixaTable'
import SelectMenu from '../../components/selectMenu';
import LancamentoFechamentoCaixaService from '../../app/service/contasAReceber/lancamentoFechamentoCaixaService';
import PDVCaixaInput from '../../components/pdvCaixa/pdvCaixaInput';
import ConstantsUtil from '../../context/constantsUtil';

class RelatorioFechamentoCaixa extends React.Component {

    constructor(){
        super()
        this.relatorioFechamentoCaixaService = new RelatorioFechamentoCaixaService()
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService()
    }

    state = {

        selectedFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        // errorFilterErrorMessgae: '',

        initialDate: '',
        initialDateView: '',
        inputInitialDateErrorClass: "",
        errorInitialDateMessage: "",
        
        finalDate: '',
        finalDateView: '',
        inputFinalDateErrorClass: "",
        errorFinalDateMessage: "",

        especificar: false,

        selectedTurno: '',
        inputTurnoErrorClass: '',


        selectedPdvCaixaId: '',
        inputPdvCaixaErrorClass: '',
        errorPdvMessage: 'Informe o Ponto de Venda',

        relatorioFechamentoCaixa: [],
        loadingTable: false,

        habilitaBusca: true,

        relatorio: {},
    }

    handleChange =  async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({[name]: value})
    }

    handleEspecificarChange = (e) => {
        this.setState({especificar: e.value})
        this.setState({selectedPdvCaixaId: ''})
        this.setState({selectedTurno: ''})
    }

    handleFilterChange = (selectedFilterType) => {
        this.setState({selectedFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    desabilitaBusca = () => {
        this.setState({habilitaBusca: false})
    }

    resetView = () => {
        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputInitialDateErrorClass: ""})
        this.setState({errorInitialDateMessage: ""})
        
        this.setState({inputFinalDateErrorClass: ""})
        this.setState({errorFinalDateMessage: ""})

        this.setState({inputPdvCaixaErrorClass: ''})

        this.setState({inputTurnoErrorClass: ''})
    }

    checkData = () => {
        
        var check = true;

        if(!this.state.selectedFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        if(this.state.initialDate && this.state.finalDate){
            if(this.state.initialDateView > this.state.finalDateView){
                check = false
                popUp.infoPopUp(ConstantsUtil.dataInicialAnteriorFinalMessage)
           }
        }

        if(this.state.especificar){
            if(!this.state.selectedPdvCaixaId){
                this.setState({inputPdvCaixaErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }
            if(!this.state.selectedTurno){
                this.setState({inputTurnoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }
        }

        return check;
    }

    callSearch = () => {
        this.resetView()
        if(this.checkData()){
            this.toggleButton()
        }
    }


    render (){

        const renderCaixaAndTurno = () => {
            if(this.state.especificar)
            return (
                <>

                <div className="col-md-2">
                    <PDVCaixaInput
                        selectedPdvCaixaId={this.state.selectedPdvCaixaId}
                        handleChange={this.handleChange}
                        inputPdvCaixaErrorClass={this.state.inputPdvCaixaErrorClass}
                        disabled={!this.state.habilitaBusca}
                    />
                </div>
                
                <div className="col-md-2">
                <label htmlFor="icon">Turno</label>
                <SelectMenu
                    className={"form-control " + this.state.inputTurnoErrorClass}
                    name="selectedTurno"
                    list={this.lancamentoFechamentoCaixaService.turnoArray} 
                    value={this.state.selectedTurno}
                    onChange={this.handleChange}
                    disabled={!this.state.habilitaBusca}
                /> 
                <div className="invalid-feedback">{ConstantsUtil.errorTurnoMessage}</div>
                </div>                


                </>
            )
        }

        const renderButtons = () => {

            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                    />
                )
            }

            return (
                <>
                    {/* <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    /> */}
                </>
            )
        }

        const renderTable = () => {
            if( !this.state.habilitaBusca )
            return (
                <>
                
                {/* <br /> */}

                {/* <div className="bs-docs-section" > */}
                    <RelatorioFechamentoCaixaTable
                        dataInicial = {this.state.initialDate}
                        dataFinal = {this.state.finalDate}
                        especificar = {this.state.especificar}
                        pdvCaixaId = {this.state.selectedPdvCaixaId}
                        turno = {this.state.selectedTurno}
                        scrollHeight="400px"
                    />
                {/* </div> */}
                </>

            )
        }

        const renderForm = () => {
            return (
                <div style={{ display: this.state.habilitaBusca ? 'block' : 'none' }}>
                <div className="row">

                <DateIntervalCalendar
                    htmlFor="SelectDatePeriod"
                    label="Escolher período"
                    handleFilterChange={this.handleFilterChange}
                    handleDateChange={this.handleDateChange}
                    missingFilterType={this.state.missingFilterType}
                    missingAnyDate={this.state.missingAnyDate}
                    disabled={!this.state.habilitaBusca}
                />

                </div>

                <br />

                <div className="row">
                    <div className = "col-md-3">
                    <h6>Especificar Caixa e Turno? (Opcional)</h6>
                    <InputSwitch 
                        checked={this.state.especificar}
                        onChange={this.handleEspecificarChange}
                        disabled={!this.state.habilitaBusca}
                        tooltip = {"Caso deixe desabilitado, então os valores dos caixas e turnos serão somados para cada dia."}
                        tooltipOptions={{className: 'custom-tooltip'}}                                 
                    />
                    </div>
                    
                    {renderCaixaAndTurno()}
                </div>

                <br />

                </div>
            )
        }

        const renderVoltarButton = () => {
            if(!this.state.habilitaBusca)
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return (
            <div className="bs-docs-section" >
            <Card
                title = "Relatório de Fechamento de Caixa"
                rightComponent={renderVoltarButton()}
            >
                <div className = "col-md-12">
                    {/* <div className="row">

                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        label="Escolher período"
                        handleFilterChange={this.handleFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                    />
                    
                    </div>

                    <br />

                    <div className="row">
                        <div className = "col-md-3">
                        <h6>Especificar Caixa e Turno? (Opcional)</h6>
                        <InputSwitch 
                            checked={this.state.especificar}
                            onChange={this.handleEspecificarChange}
                            disabled={!this.state.habilitaBusca}
                            tooltip = {"Caso deixe desabilitado, então os valores dos caixas e turnos serão somados para cada dia."}
                            tooltipOptions={{className: 'custom-tooltip'}}                                 
                        />
                        </div>
                        
                        {renderCaixaAndTurno()}
                    </div> */}

                    {renderForm()}

                    {renderButtons()}

                    {renderTable(this.state.relatorio ? this.state.relatorio : {})}


                </div>
            </Card>

            <div className="d-flex "/>
            
            </div>   
        )
    }

}

RelatorioFechamentoCaixa.contextType = AuthContext

export default withRouter(RelatorioFechamentoCaixa)