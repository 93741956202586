import React from 'react'
import { withRouter } from 'react-router-dom'

import Card from '../../components/card'
import { AuthContext } from '../../main/authProvider'
import GeneralServices from '../../app/service/generalServices'
import HandleErrorService from '../../app/service/handleErrorService'
import * as popUp from '../../components/toastr'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import FileUploadCard from '../../components/fileUploadCard'
import DialogFooter from '../../components/dialogFooter'
import ConstantsUtil from '../../context/constantsUtil'
import BoletoService from '../../app/service/conciliacaoBancaria/boletoRecebimentoService'
import AuthService from '../../app/service/authService'
import BoletoRecebimentoTable from '../../components/conciliacaoBancaria/boletoRecebimentoTable'

class Boletos extends React.Component {

    constructor(){
        super()
        this.boletoService = new BoletoService()
    }
    
    state = {
        displayImportacaoBoletosDialog: true,
        renderTable: false,
        remessaBoleto: null,
        valorTotal: null,
    }

    hideDialog = () => {
        this.setState({displayImportacaoBoletosDialog: false})
    }

    onFinishUpload = (progressKey) => {
        this.boletoService.getUploadResult(progressKey)
        .then(response => {
            // console.log("onFinishUpload: ", response.data)
            this.setState({remessaBoleto: response.data})
            this.setState({displayImportacaoBoletosDialog: false})
            this.setState({renderTable: true})
            this.onValueChange(this.state.remessaBoleto.movimentoList)
            // this.setState({completedUpload: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    save = () => {
        AuthService.startBlockUi()
        this.boletoService.save(this.state.remessaBoleto)
        .then(response => {
            popUp.successPopUp("Lançamentos salvos com sucesso!")
            this.setState({displayImportacaoBoletosDialog: true})
            this.setState({renderTable: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            AuthService.stopBlockUi()
        })

    }

    onValueChange = (currentList) => {
        let valorTotal = 0.0
        currentList.forEach(item => {
            valorTotal += item.valor
        })
        this.setState({valorTotal})
        return valorTotal
    }

    render() {

        const renderTable = () => {
            if(this.state.renderTable){
                return (
                    <>

                        <Button
                            label={`Valor Total: R$ ${GeneralServices.valueBodyTemplate(this.state.valorTotal)}`}
                            style={{marginBottom: '15px'}}
                        />

                        <BoletoRecebimentoTable
                            list = {this.state.remessaBoleto.movimentoList}
                            onValueChange={this.onValueChange}
                        />

                        <br />

                        <Button 
                            label="Salvar"
                            icon="pi pi-save"
                            onClick = {this.save}
                        />

                    </>                                         
                )
            }
        }

        const footer = (
            <DialogFooter
                save = {this.callSave}
                hideDialog = {this.hideDialog}
                disabled
            />
        )

        const renderDialog = () => {
            return (
                <Dialog
                    visible={this.state.displayImportacaoBoletosDialog}
                    style={{ width: '95vw'}}
                    header="Importar Arquivos de Remessa"
                    modal
                    className="p-fluid"
                    footer={footer}
                    onHide={this.hideDialog}
                >
                    <FileUploadCard
                        // uploadInProgress = {this.setIfIsInProgress}
                        accept=".txt"
                        uploadDescription={ConstantsUtil.importacaoArquivosRemessaBoletos}
                        onFinish={this.onFinishUpload}
                    />

                </Dialog>
            )
        }

        const renderImportarBoletosButton = () => {
            return (
            <Button 
                label="Importar Boletos (txt)"
                className="p-button-success"
                icon="pi pi-upload"
                onClick = {() => this.setState({displayImportacaoBoletosDialog: true})}
                // disabled={!this.state.habilitaBusca}
            />
            )
        }        

        return(
            <div className="bs-docs-section">
                <Card 
                    title = "Boletos"
                    rightComponent={renderImportarBoletosButton()}
                >

                    {renderDialog()}
                    {renderTable()}

                </Card>

            </div>
        )

    }


}

Boletos.contextType = AuthContext

export default withRouter(Boletos)