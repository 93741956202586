import WorkerApiService from "../../workerApiServices";

class BoletoRecebimentoService extends WorkerApiService {
 
    constructor() {
        super('/api/boletoRecebimento')
    }

    getUploadResult(progressKey){
        let params = `?`
        params = `${params}&progressKey=${progressKey}`
        return this.get(`/getUploadResult${params}`)
    }

    save(object){
        return this.post("/save", object)
    }

    getByDateAndTipoDeRecebimento(date, idTipoDeRecebimento){
        let params = `?`
        params = `${params}&dataVencimento=${date}`
        params = `${params}&idTipoDeRecebimento=${idTipoDeRecebimento}`
        return this.get(`/getByDateAndTipoDeRecebimento${params}`)        
    }
    
}

export default BoletoRecebimentoService